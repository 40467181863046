




































































import { Component, Vue, Model, Prop, Watch, Emit } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class Transfer extends Vue {
  @Model('cancel', { type: Boolean }) private readonly visible!: boolean
  @Prop({ default: '' }) private readonly title!: string
  @Prop({ default: () => [] }) private defaultCandidateData!: Array<any>
  @Prop({ default: () => [] }) private defaultSelectedData!: Array<any>
  private searchText = ''
  private candidateData: Array<any> = []
  private selectedData: Array<any> = []

  public get filteredCandidateData(): Array<any> {
    const data = cloneDeep(this.candidateData)
    const text = this.searchText.toLowerCase().trim()
    const newData = data.filter(
      item => item.title.toLowerCase().indexOf(text) !== -1 && !item.selected
    )
    return newData
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(newVal): void {
    if (newVal) {
      this.searchText = ''
      this.selectedData = cloneDeep(this.defaultSelectedData)
      this.candidateData = cloneDeep(this.defaultCandidateData)
      const selectedIds = this.selectedData.map(item => item.id)
      let candidateData = cloneDeep(this.candidateData)
      candidateData.forEach(item => {
        if (selectedIds.includes(item.id)) {
          item.selected = true
        }
      })
      this.candidateData = cloneDeep(candidateData)
    }
  }

  private onSearch(value): void {
    this.searchText = value
  }

  private selectItem(item, idx): void {
    let candidateData = cloneDeep(this.candidateData)
    candidateData.forEach(it => {
      if (it.id === item.id) {
        it.selected = true
      }
    })
    this.candidateData = cloneDeep(candidateData)
    this.selectedData.push(item)
  }

  private selectAll(): void {
    let candidateData = cloneDeep(this.candidateData)
    candidateData.forEach(item => {
      item.selected = true
    })
    this.candidateData = cloneDeep(candidateData)
    this.selectedData = cloneDeep(candidateData)
  }

  private removeAll(): void {
    let candidateData = cloneDeep(this.candidateData)
    candidateData.forEach(item => {
      item.selected = false
    })
    this.candidateData = cloneDeep(candidateData)
    this.selectedData = []
  }

  private removeItem(id, index): void {
    let candidateData = cloneDeep(this.candidateData)
    candidateData.forEach(item => {
      if (item.id === id) {
        item.selected = false
      }
    })
    this.candidateData = cloneDeep(candidateData)
    this.selectedData.splice(index, 1)
  }

  @Emit('cancel')
  private closeModal(): boolean {
    return false
  }

  @Emit('confirm')
  private confirm(): Array<any> {
    this.closeModal()
    return this.selectedData
  }
}
