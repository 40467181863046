var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"moment-editor",attrs:{"layout":"horizontal","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"form":_vm.momentEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.time')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'time',
        {
          initialValue: _vm.moment(),
          rules: [
            {
              required: 'true',
              message: this.$t('courseMaterial.selectCourseTime'),
            } ],
        } ]),expression:"[\n        'time',\n        {\n          initialValue: moment(),\n          rules: [\n            {\n              required: 'true',\n              message: this.$t('courseMaterial.selectCourseTime'),\n            },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"disabledDate":_vm.disabledDate,"disabledTime":_vm.disabledDateTime,"showTime":{
        format: 'HH:mm',
      },"format":"YYYY-MM-DD HH:mm"}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('diary.relationstudent'),"required":""}},[(_vm.selectedStudents.length)?_c('div',{staticClass:"tag-container"},_vm._l((_vm.selectedStudents),function(item){return _c('a-tag',{key:item.id,attrs:{"visible":true,"closable":""},on:{"close":function($event){return _vm.removeSelectedStudent(item.id)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e(),_c('a-button',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'studentIds',
        {
          rules: [
            {
              required: true,
              validator: _vm.validateStudents,
            } ],
        } ]),expression:"[\n        'studentIds',\n        {\n          rules: [\n            {\n              required: true,\n              validator: validateStudents,\n            },\n          ],\n        },\n      ]"}],attrs:{"block":"","type":"dashed","icon":"plus"},on:{"click":_vm.addStudent}},[_vm._v(" "+_vm._s(_vm.$t('diary.addStudent'))+" ")])],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.description'),"required":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'content',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('tips.inputContent'),
            },
            {
              max: 2000,
              message: _vm.$t('common.textLimit2000'),
            } ],
        } ]),expression:"[\n        'content',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('tips.inputContent'),\n            },\n            {\n              max: 2000,\n              message: $t('common.textLimit2000'),\n            },\n          ],\n        },\n      ]"}],attrs:{"autoSize":{
        minRows: 4,
        maxRows: 8,
      },"placeholder":_vm.$t('common.description')}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.images')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments', {}]),expression:"['attachments', {}]"}],attrs:{"fileList":_vm.attachments,"customRequest":_vm.customRequest,"list-type":"picture-card","remove":_vm.removeFile,"disabled":_vm.uploadAttachmentLoadingCount > 0,"accept":"image/*","multiple":""},on:{"preview":_vm.handlePreview}},[(_vm.attachments.length < 15)?_c('a-spin',{attrs:{"spinning":_vm.uploadAttachmentLoadingCount > 0}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(("(" + (_vm.attachments.length) + "/15)"))+" ")])],1):_vm._e()],1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('diary.shareParent')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'shareWithParents',
        {
          initialValue: false,
        } ]),expression:"[\n        'shareWithParents',\n        {\n          initialValue: false,\n        },\n      ]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('common.true')))]),_c('a-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('common.false')))])],1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('diary.shareStudent')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'shareWithStudents',
        {
          initialValue: false,
        } ]),expression:"[\n        'shareWithStudents',\n        {\n          initialValue: false,\n        },\n      ]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('common.true')))]),_c('a-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('common.false')))])],1)],1),_c('a-form-item',{attrs:{"label":" ","colon":false}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.uploadAttachmentLoadingCount > 0},on:{"click":_vm.saveMoment}},[_vm._v(_vm._s(_vm.$t('common.publish')))]),_c('a-button',{attrs:{"loading":_vm.loading,"disabled":_vm.uploadAttachmentLoadingCount > 0},on:{"click":_vm.saveDraft}},[_vm._v(_vm._s(_vm.$t('moment.saveDraft')))]),_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1),_c('group-transfer',{attrs:{"title":_vm.transferTitle,"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.groupTransferVisible),callback:function ($$v) {_vm.groupTransferVisible=$$v},expression:"groupTransferVisible"}}),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null,"centered":""},on:{"cancel":function($event){_vm.previewVisible = false}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"img","src":_vm.previewImage}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }