



































import { exportXlsx, writeFile } from '@/utils/xlsx'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class reservedTable extends Vue {
  // 接受父组件的值
  @Prop({ default: () => [] }) private readonly data!: Array<any>
  @Prop() private readonly loading!: boolean
  @Prop() private readonly excelName!: string
  @Prop() private readonly align!: string

  private exportLoading: any = false

  private get columns(): Array<Object> {
    return [
      {
        key: 'reserveTime',
        title: this.$t('parentsMeeting.meetingTime'),
        ellipsis: true,
        align: this.align,
        scopedSlots: { customRender: 'meetingTime' },
      },
      {
        dataIndex: 'student',
        title: this.$t('common.student'),
        ellipsis: true,
        align: this.align,
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        dataIndex: 'gender',
        title: this.$t('myClass.student.gender'),
        align: this.align,
        scopedSlots: { customRender: 'gender' },
      },
      {
        dataIndex: 'teacherRelation',
        ellipsis: true,
        title: this.$t('common.class'),
        align: this.align,
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        dataIndex: 'parentNameString',
        ellipsis: true,
        title: this.$t('common.parent'),
        align: this.align,
      },
      {
        dataIndex: 'parentEmail',
        ellipsis: true,
        title: this.$t('myClass.student.parentEmail'),
        align: this.align,
      },
    ]
  }

  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'reserveTime',
        title: this.$t('parentsMeeting.meetingTime'),
        width: 30,
      },
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 10,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 10,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        width: 10,
      },
      {
        dataIndex: 'gender',
        title: this.$t('myClass.student.gender'),
        width: 10,
      },
      {
        dataIndex: 'teacherRelation',
        title: this.$t('common.class'),
        width: 30,
      },
      {
        dataIndex: 'parentNameString',
        title: this.$t('common.parent'),
        width: 30,
      },
      {
        dataIndex: 'parentEmail',
        title: this.$t('myClass.student.parentEmail'),
        width: 30,
      },
    ]
  }

  private async exportExcel(): Promise<void> {
    this.exportLoading = true
    const students = this.data.map(item => ({
      ...item,
      reserveTime: Vue.filter('doubleMoment')([item.start, item.end], {
        pattern: 'YYYY.MM.DD',
        secondPat: 'HH:mm',
      }),
      gender: this.$t(`common.gender.${item.gender}`),
    }))
    const file = exportXlsx(this.excelColumns, students)
    const ws = file.getWorksheet(1)

    ws.eachRow(row => {
      row.height = 30
      row.eachCell(cell => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        }
        cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
      })
    })

    await writeFile(file, this.excelName)
    this.exportLoading = false
  }
}
