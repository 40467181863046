


















import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { getSchoolInfo } from '@/utils/utils'
import { appList } from '@/constant/constant'

@Component
export default class OtherApp extends Vue {
  private schoolId = (getSchoolInfo() || {}).schoolId

  private get showChild(): boolean {
    return this.$route.name !== 'otherApp'
  }
  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get apps(): Array<any> {
    let all = Object.keys(appList).includes(this.schoolId.toString())
      ? appList[this.schoolId]
      : appList.default
    return all.filter(item => this.operationAuths.includes(item.auth))
  }

  private toPage(value): void {
    this.$router.push({ name: value }).catch(err => {})
  }
}
