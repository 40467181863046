


























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { NoticeController, DropDownController, TeacherController } from '@/services/request.service'
import Transfer from '@/components/Transfer.vue'
import GroupTransfer from '@/components/GroupTransfer.vue'
import Upload from '@/components/Upload.vue'
// import ConfirmModal from './ConfirmModal.vue'
import moment from 'moment'
import { SimditorVue } from '@/components/simditor/Simditor'
import { toPage, guid, judgeVnode } from '@/utils/utils'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
])

// const operations = {
//   outbox: function(id) {
//     return MessageController.getFromDetail(id)
//   },
//   inbox: function(id) {
//     return MessageController.getToDetail(id)
//   }
// }

@Component({
  components: {
    Transfer,
    GroupTransfer,
    Upload,
    // ConfirmModal,
    SimditorVue,
  },
})
export default class NoticeEditor extends Vue {
  private sending: any = false

  private canLeave: any = false
  private confirmData: any = []
  private confirmVis: any = false
  private formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  }

  private sections: Array<any> = []
  private noticeForm: any = {
    editTip: false,
    sendMail: false,
    title: '',
    department: undefined,
    tag: [],
    content: '',
    attachments: [],
    publishTiming: null,
    stickyOver: null,
    recipientScope: [],
    editInstruction: '',
  }
  private publishTiming: boolean = false
  private sticky: boolean = false
  private parentSectionIds: any = []
  private studentSectionIds: any = []
  private parentRecipientScope: string = ''
  private studentRecipientScope: string = ''
  private departmentList: any = []
  private tagList: any = []
  private moment = moment

  private uploading: any = false
  private teacherLoading: boolean = false
  private studentLoading: boolean = false
  private parentLoading: boolean = false

  // 上一次编辑提示
  private lastEditTip: string = ''

  //自定义范围校验
  private scopeErr: boolean = false
  private scopeErrHelp: string = ''

  // 发送预览邮件
  private sendingPreview: boolean = false

  private get locale(): string {
    return this.$store.state.locale
  }

  private get type(): any {
    return this.$route.query.type
  }

  private get noticeId(): any {
    return this.$route.query.id
  }

  private get formatSections(): any {
    return this.sections.map(item => {
      return {
        children: item.subOptions.map(child => ({
          key: child.key,
          title: this.locale === 'zh' ? child.value : child.enValue || child.value,
          value: child.key,
        })),
        title: `${item.value}-${item.enValue}`,
        key: `${item.enValue}_${item.extraValue}_${item.key}`,
        value: `${item.enValue}_${item.extraValue}_${item.key}`,
      }
    })
  }

  private disabledDate(current): any {
    return current && current < moment()
  }

  private validateTitle(rule, value, cb): void {
    const form = this.$refs['noticeForm'] as any
    let realStrLength = value.trim().replace(/[^\x00-\xff]/g, '01').length
    if (realStrLength > 100) {
      cb(this.$t('tips.textLimitZh', { num: 100 }))
    } else {
      cb()
    }
  }

  private cancel(): void {
    this.$router.push({ name: 'contentManagement' }).catch(err => console.error(err))
  }

  private created(): void {
    this.getDropDownInfo()
    this.getDetail()
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getSectionCascade(),
      NoticeController.departDropDown(),
      NoticeController.tagDropdown(),
      NoticeController.departsAllDropDown(),
    ])
      .then(res => {
        this.sections = res[0].data

        this.departmentList =
          this.type === 'add'
            ? res[1].data.map(item => {
                return {
                  noticeDepartmentId: item.key,
                  name: item.value,
                }
              })
            : res[3].data.map(item => {
                return {
                  noticeDepartmentId: item.key,
                  name: item.value,
                }
              })
        this.tagList = res[2].data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getDetail(): void {
    if (this.type !== 'edit') return
    const messageId = parseInt(this.noticeId as any, 10)
    NoticeController.getNotice(messageId)
      .then(res => {
        const data = res.data

        // 李沁颖 2021/8/27
        // this.noticeForm.editTip = data.commentFlag
        // this.noticeForm.sendMail = data.mailNotice
        // this.noticeForm.editInstruction = data.comment
        this.lastEditTip = data.comment

        this.noticeForm.title = data.title
        this.noticeForm.department = data.noticeDepartment.key
        this.noticeForm.tag = data.noticeTags.map(item => item.key)
        this.noticeForm.content = data.content
        this.noticeForm.attachments = data.attachments.map(item => {
          return {
            name: item.resourceName,
            uid: item.resourceId,
            url: item.resourceUrl,
            status: 'done',
            percent: 0,
          }
        })
        this.noticeForm.publishTiming = data.publishNow ? null : moment(data.publishTime)
        this.noticeForm.stickyOver = data.sticky ? moment(data.stickyOver) : null
        this.noticeForm.recipientScope = data.publishRanges.map(item => item.range)

        this.publishTiming = !data.publishNow
        this.sticky = data.sticky

        if (this.noticeForm.recipientScope.includes('parent')) {
          const parent = data.publishRanges.filter(item => item.range === 'parent')[0]
          this.parentSectionIds = parent.sections.map(item => item.key)
          this.parentRecipientScope = parent.type
        }
        if (this.noticeForm.recipientScope.includes('student')) {
          const student = data.publishRanges.filter(item => item.range === 'student')[0]
          this.studentSectionIds = student.sections.map(item => item.key)
          this.studentRecipientScope = student.type
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  /**
   *  处理级联section
   */
  private onParentSectionChange(value, label, extra) {
    const isVNode = judgeVnode(extra.triggerNode)
    this.parentSectionIds = (extra.allCheckedNodes || [])
      .map(item => {
        if (!isVNode) {
          if (item.children) {
            return item.children.map(item => item.node.key)
          } else {
            return item.node.key
          }
        } else {
          if (item.componentOptions.children.length) {
            return item.componentOptions.children.map(item => item.data.key)
          } else {
            return item.data.key
          }
        }
      })
      .flat()
  }

  private onStudentSectionChange(value, label, extra) {
    const isVNode = judgeVnode(extra.triggerNode)
    this.studentSectionIds = (extra.allCheckedNodes || [])
      .map(item => {
        if (!isVNode) {
          if (item.children) {
            return item.children.map(item => item.node.key)
          } else {
            return item.node.key
          }
        } else {
          if (item.componentOptions.children.length) {
            return item.componentOptions.children.map(item => item.data.key)
          } else {
            return item.data.key
          }
        }
      })
      .flat()
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      const message = {
        title: this.noticeForm.title,
        content: this.noticeForm.content,
        attachments: this.noticeForm.attachments?.length,
        editInstruction: this.noticeForm.editInstruction,
      }
      if (Object.values(message).some(item => item)) {
        if (!this.canLeave) {
          this.$confirm({
            title: this.$t('common.unsaveConfirm') as string,
            onOk: () => {
              next()
            },
            onCancel: () => {
              next(false)
            },
          })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private send(previewMail): void {
    const form = this.$refs['noticeForm'] as any
    form.validate(valid => {
      if (!valid) return false
      if (previewMail) {
        this.sendingPreview = true
      } else {
        this.sending = true
      }
      const form = this.noticeForm
      const condition = {
        noticeId: this.noticeId ? parseInt(this.noticeId as any, 10) : undefined,
        // noticeId: parseInt(this.noticeId as any, 10),
        title: form.title,
        noticeDepartment: form.department,
        noticeTags: form.tag,
        content: form.content,
        attachments: form.attachments.map(item => item.uid),

        publishNow: !this.publishTiming,
        publishTime: this.publishTiming ? form.publishTiming.valueOf() : undefined,
        sticky: this.sticky,
        stickyOver: this.sticky ? form.stickyOver.valueOf() : undefined,
        mailNotice: form.sendMail,
        publishRanges: form.recipientScope.map(item => {
          return {
            range: item,
            sectionIds:
              item === 'teacher'
                ? undefined
                : item === 'parent'
                ? this.parentSectionIds
                : this.studentSectionIds,
            type:
              item === 'teacher'
                ? undefined
                : item === 'parent'
                ? this.parentRecipientScope
                : this.studentRecipientScope,
          }
        }),
        commentFlag: form.editTip,
        // comment: form.editInstruction,
        comment:
          form.editTip && form.editInstruction.length ? form.editInstruction : this.lastEditTip,
      }

      if (previewMail) {
        NoticeController.sendNoticeDemo(condition)
          .then(res => {
            this.$message.success(this.$tc('common.sendSuccess'))
          })
          .catch(err => console.log(err))
          .finally(() => (this.sendingPreview = false))
      } else {
        NoticeController.saveNotice(condition)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.canLeave = true
            this.cancel()
          })
          .catch(err => console.log(err))
          .finally(() => (this.sending = false))
      }
    })
  }

  // private validateScope(rule, value, callback): void {
  //   if (value.length) {
  //     if(value.includes('student') && !this.studentSectionIds.length || value.includes('parent') && !this.parentSectionIds.length ){
  //       callback(this.$t('notice.selectTime'))
  //     }else{
  //       callback()
  //     }
  //   } else {
  //     callback(this.$t('notice.selectScope'))
  //   }
  // }

  private validateBeforeSend(previewMail): void {
    if (!this.noticeForm.recipientScope.length) {
      this.scopeErr = true
      this.scopeErrHelp = this.$tc('notice.selectScope')
      return
    } else {
      if (
        (this.noticeForm.recipientScope.includes('student') && !this.studentSectionIds.length) ||
        (this.noticeForm.recipientScope.includes('parent') && !this.parentSectionIds.length)
      ) {
        this.scopeErr = true
        this.scopeErrHelp = this.$tc('tips.selectGrade')
        return
      } else {
        this.scopeErr = false
        this.scopeErrHelp = ''
        this.send(previewMail)
      }
    }
  }

  @Watch('noticeForm.recipientScope', {
    immediate: true,
    deep: true,
  })
  private onValueChange(newVal): void {
    if (newVal && newVal.includes('parent')) {
      this.parentRecipientScope = this.parentRecipientScope ? this.parentRecipientScope : 'all'
    }
    if (newVal && newVal.includes('student')) {
      this.studentRecipientScope = this.studentRecipientScope ? this.studentRecipientScope : 'all'
    }
  }

  // 自定义校验
  @Watch('studentSectionIds', {
    immediate: true,
  })
  private onStudentChange(newVal): void {
    if (newVal) {
      this.scopeErr = false
      this.scopeErrHelp = ''
    } else {
      this.scopeErr = true
      this.scopeErrHelp = this.$tc('tips.selectGrade')
    }
  }
  @Watch('parentSectionIds', {
    immediate: true,
  })
  private onParentChange(newVal): void {
    if (newVal) {
      this.scopeErr = false
      this.scopeErrHelp = ''
    } else {
      this.scopeErr = true
      this.scopeErrHelp = this.$tc('tips.selectGrade')
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (to.query.type === 'add') {
      to.meta.routerName = 'noticeAdd'
    } else {
      to.meta.routerName = 'noticeEdit'
    }
  }
}
