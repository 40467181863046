




















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import { MeetingsController, TeacherController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage } from '@/utils/utils'
import { createPagination } from '@/constant/constant'

@Component({
  computed: {
    ...mapState({
      operationAuths: 'operationAuths',
    }),
  },
  components: {
    FlexTooltip,
    filterGroup,
  },
})
export default class parentsMeeting extends Vue {
  private filter: any = {
    name: '',
    teacherId: undefined,
  }
  private teachers: Array<any> = []
  private loading: boolean = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private meetingData: Array<any> = []

  private get columns(): Array<Object> {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        title: this.$t('parentsMeeting.meetingName'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'meetingTime',
        key: 'meetingTime',
        title: this.$t('parentsMeeting.meetingDate'),
        scopedSlots: { customRender: 'meetingTime' },
        ellipsis: true,
      },
      {
        key: 'reserveTime',
        title: this.$t('parentsMeeting.reserveTime'),
        ellipsis: true,
        scopedSlots: { customRender: 'reserveTime' },
      },
      {
        dataIndex: 'appointedCount',
        key: 'reservedNum',
        title: this.$t('parentsMeeting.reservedNum'),
        ellipsis: true,
        scopedSlots: { customRender: 'reservedNum' },
      },
      {
        dataIndex: 'classRoom',
        title: this.$t('parentsMeeting.classroom'),
        ellipsis: true,
        scopedSlots: { customRender: 'classroom' },
      },
      {
        dataIndex: 'status',
        key: 'status',
        title: this.$t('parentsMeeting.status'),
        scopedSlots: { customRender: 'status' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get showChild(): boolean {
    return this.$route.name !== 'parentsMeeting'
  }

  private get currentTeacherId(): boolean {
    return this.$store.state.memberId
  }

  private get teacherName(): any {
    const teacher = this.teachers.find(item => item.teacherId === this.filter.teacherId)
    return `${teacher.enName} ${teacher.name}`.trim()
  }

  @Watch('currentTeacherId', { immediate: true })
  private onTeacherIdChange(val) {
    if (val) {
      this.filter.teacherId = val
      this.getDropDownInfo()
    }
  }

  private changeName = debounce(() => {
    this.getData()
  }, 500)

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    if (this.loading) return
    this.meetingData = []
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      name: this.filter.name === '' ? undefined : this.filter.name.trim(),
      teacherId: this.filter.teacherId || undefined,
    }
    this.loading = true
    MeetingsController.getMeetings(
      condition.pageCurrent,
      condition.pageSize,
      condition.name,
      condition.teacherId
    )
      .then(res => {
        this.meetingData = res.data.items.map(item => {
          let meetingTime = item.dates.map(element => {
            let { startTime, endTime } = element
            let day = moment(startTime).format('YYYY[.]MM[.]DD')
            let start = moment(startTime).format('HH:mm')
            let end = moment(endTime).format('HH:mm')
            return `${day} ${start}~${end}`
          })
          this.$set(item, 'meetingTime', meetingTime.join('；'))
          // (item.startTime as any) = moment(item.startTime).format('YYYY[.]MM[.]DD HH:mm')
          return item
        })
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.meetingData, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private viewDetails(meetingId, type): void {
    this.$router
      .push({
        name: 'parentsMeetingDetail',
        params: { meetingId },
        query: { type, teacherId: this.filter.teacherId, teacherName: this.teacherName },
      })
      .catch(err => {})
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(pagination)
  }

  private getDropDownInfo(): void {
    TeacherController.getListAll()
      .then(res => {
        this.teachers = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.getData()
      })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
  }
}
