





































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import reservedTable from './ReservedTable.vue'
import { MeetingsController } from '@/services/request.service'
import moment from 'moment'
import meetingEditForm from './MeetingEditForm.vue'

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
    reservedTable,
    meetingEditForm,
  },
})
export default class meetingDetail extends Vue {
  private reservedData: Array<any> = []
  private meetingInfo: any = {}
  private loading: boolean = false
  private meetingId: any
  private teacherId: any
  private type: any
  private excelName: any = ''

  private get infoList(): Array<any> {
    let data = this.dealDetailData(this.meetingInfo)
    return [
      {
        key: 'meetingTime',
        label: this.$t('parentsMeeting.meetingDate'),
        value: data.meetingTime,
      },
      {
        key: 'reserveTime',
        label: this.$t('parentsMeeting.reserveTime'),
        value: Vue.filter('doubleMoment')([data.startTime, data.endTime], 'YYYY.MM.DD HH:mm'),
      },
      {
        key: 'durationInMin',
        label: this.$t('parentsMeeting.duration'),
        value: data.durationInMin,
      },
      {
        key: 'intervalInMin',
        label: this.$t('parentsMeeting.interval'),
        value: data.intervalInMin,
      },
      // {
      //   key: 'students',
      //   label: this.$t('parentsMeeting.reservableStudents'),
      //   value: data.students
      // },
    ]
  }
  private get username(): any {
    // return this.$store.state.username
    return this.$route.query.teacherName || ''
  }

  private getMeetingInfoData(meetingId, teacherId): void {
    this.loading = true
    MeetingsController.getMeeting(meetingId, teacherId)
      .then(res => {
        this.meetingInfo = res.data
        if (this.type === 'view') {
          this.reservedData = res.data.appointmentPeriods.map(item => {
            let relation = this.$t(`relation.${item.appointor.relation}`)
            return {
              // reservedPerson: `${item.appointor.student} ${relation}`,
              ...item.appointor,
              start: item.start,
              end: item.end,
              appointmentId: item.appointmentId,
              meetingTime: this.getTimeString({
                startTime: item.start,
                endTime: item.end,
              }),
              parentNameString: `${item.appointor.parentName}${
                item.appointor.relation ? `(${this.$t(`relation.${item.appointor.relation}`)})` : ''
              }`,
            }
          })
        }
        this.excelName = `${this.meetingInfo.name}_${this.username}_Booking List.xlsx`
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private dealDetailData(data): any {
    if (!Object.keys(data).length)
      return {
        durationInMin: '',
        intervalInMin: '',
        students: '',
        reserveTime: '',
        meetingTime: '',
      }
    let meetingTime = data.dates
      .map(item => {
        return this.getTimeString(item)
      })
      .join('、')
    let reserveBeginTime = moment(data.startTime).format('YYYY[.]MM[.]DD HH:mm')
    return {
      ...data,
      meetingTime,
      students: data.students.join('，'),
      durationInMin: data.durationInMin + 'mins',
      intervalInMin: data.intervalInMin + 'mins',
      reserveBeginTime,
    }
  }

  private getTimeString(timeItem): string {
    let day = moment(timeItem.startTime).format('YYYY[.]MM[.]DD')
    let start = moment(timeItem.startTime).format('HH:mm')
    let end = moment(timeItem.endTime).format('HH:mm')
    return `${day} ${start}~${end}`
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.meetingId = to.params.meetingId
    this.type = to.query.type || 'view'
    this.teacherId = to.query.teacherId
    this.$route.meta.refresh = true
    if (!to.query.teacherId) {
      this.$router.replace({ name: 'parentsMeeting' })
    }
    this.meetingInfo = {}
    this.getMeetingInfoData(this.meetingId, this.teacherId)
  }

  private beforeRouteLeave(to, from, next): void {
    // if (!this.$store.state.forceBack && this.type === 'edit') {
    //   if (!(this.$refs.meetingEF as any).canLeave) {
    //     this.$confirm({
    //       title: this.$t('common.unsaveConfirm') as string,
    //       onOk: () => {
    //         next()
    //       },
    //       onCancel: () => {
    //         next(false)
    //       },
    //     })
    //   } else {
    //     next()
    //   }
    // } else {
    //   next()
    // }
    next()
  }

  private refreshInfo(): void {
    this.getMeetingInfoData(this.meetingId, this.teacherId)
  }
}
