
















































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import debounce from 'lodash/debounce'
import filterGroup from '@/components/filterGroup'
import infiniteScroll from 'vue-infinite-scroll'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { RecycleScroller } from 'vue-virtual-scroller'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import LazyImage from '@/components/LazyImage.vue'
import {
  MomentsController,
  CommonController,
  DropDownController,
  CourseController,
} from '../../../services/request.service'
import { clearEmptyArray } from '@/utils/utils'
import DraftModal from './components/DraftModal.vue'
// import InfiniteList from "@/components/InfiniteList"
import { createPagination } from '@/constant/constant'
import shareStudentsIcon from '@/assets/images/OtherApp/Moment/share_students.svg?inline'
import shareParentsIcon from '@/assets/images/OtherApp/Moment/share_parents.svg?inline'
import translateIcon from '@/assets/images/OtherApp/Moment/translate.svg?inline'

@Component({
  components: {
    filterGroup,
    LazyImage,
    DraftModal,
    // InfiniteList
  },
  directives: {
    infiniteScroll,
  },
})
export default class Moment extends Vue {
  private getAllDataFlag: boolean = false
  private loading: boolean = false
  private translationVisible: boolean = false
  private contentExpand: boolean = false
  private viewStart: number = 0
  private viewEnd: number = 5
  private pagination: any = createPagination({
    current: 0,
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private isTop: boolean = true
  private draftModalShow: boolean = false
  private document = document
  private data: Array<any> = []
  private draftsData: Array<any> = []
  private actions: Array<any> = [{ type: 'like', text: '' }]
  private homeRooms: any = []
  private advisories: any = []
  private CCAClasses: any = []
  private subjectClasses: any = []
  private checkedList: Array<any> = ['student', 'parent', 'unshared']
  private filter: any = {
    date: undefined,
    studentName: '',
    teacherName: '',
    homeRoomId: [],
    advisoryId: undefined,
    courseId: [],
    ccaCourseId: undefined,
  }

  private shareStudentsIcon = shareStudentsIcon
  private shareParentsIcon = shareParentsIcon
  private translateIcon = translateIcon

  private get shareOptions(): any {
    return [
      {
        label: this.$t('common.parent'),
        value: 'parent',
      },
      {
        label: this.$t('common.student'),
        value: 'student',
      },
      {
        label: this.$t('common.unshared'),
        value: 'unshared',
      },
    ]
  }
  private get isChildRoute(): boolean {
    return this.$route.name !== 'moment'
  }
  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }
  private get userId(): any {
    return this.$store.state.memberId
  }
  private get userName(): any {
    return this.$store.state.username
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private options: any = {
    url: 'data-source',
  }

  private onScroll(params): void {
    // let el = document.getElementById('moment_root')
    // if((el || {}).scrollTop === 0){
    //   this.isTop = true
    // }
    let scrollTop = params.currentTarget.scrollTop
    this.isTop = scrollTop === 0
  }

  private changeName = debounce(() => {
    this.refresh()
  }, 500)

  private fetchData(cb): void {
    let { current, pageSize } = this.pagination
    let {
      date,
      studentName,
      teacherName,
      homeRoomId: classId,
      advisoryId: houseId,
      courseId,
      ccaCourseId,
    } = this.filter
    let shareWithParent = this.checkedList.includes('parent')
    let shareWithStudent = this.checkedList.includes('student')
    let notShared = this.checkedList.includes('unshared')
    let condition = {
      pageCurrent: current,
      pageSize: pageSize || this.pagination.defaultPageSize,
      shareWithParent: shareWithParent,
      shareWithStudent: shareWithStudent,
      notShared: notShared,
      start: (date || [])[0] ? (date || [])[0].valueOf() : undefined,
      end: (date || [])[1] ? (date || [])[1].valueOf() : undefined,
      studentName: studentName.trim() || undefined,
      teacherName: teacherName.trim() || undefined,
      classId: (classId || [])[1],
      houseId,
      courseId: (courseId || [])[1],
      ccaCourseId,
    }
    MomentsController.getMoments(condition)
      .then(res => {
        cb(res)
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
  }

  private handleInfiniteOnLoad(): void {
    if (this.loading || this.getAllDataFlag) return
    // return
    const data = this.data
    this.loading = true
    this.pagination.current++
    this.fetchData(res => {
      this.pagination.total = res.data.totalItem
      this.data = data.concat(res.data.items).map((item, index) => {
        let isLike = item.favors.filter(item => item.userId === this.userId).length !== 0
        return {
          ...item,
          index,
          date: moment(item.time).format('YYYY.MM.DD HH:mm'),
          isLike,
          translateVisible: false,
          allPhotoVisible: false,
          contentExpand: false,
        }
      })
      this.loading = false
      if (this.data.length >= res.data.totalItem) {
        this.getAllDataFlag = true
      }
    })
  }

  private translate(item): void {
    if (item.translateVisible) {
      item.translateVisible = false
    } else {
      CommonController.translate(item.content).then(res => {
        item.translateVisible = true
        this.$set(item, 'translate', res.data.value)
      })
    }
  }

  private judgeEdit(item): boolean {
    if (item.creator.userId !== this.userId || !this.operationAuths.includes('2059')) return false
    return true
  }

  private changeLike(item): void {
    MomentsController.favor(!item.isLike, item.momentId)
      .then(res => {
        if (item.isLike) {
          let index = item.favors.findIndex(favor => this.userId === favor.userId)
          item.favors.splice(index, 1)
        } else {
          item.favors.push({
            userId: this.userId,
            favorName: this.userName,
          })
        }
        item.isLike = !item.isLike
      })
      .catch(err => console.log(err))
  }

  private deleteDraft(params): void {
    MomentsController.deleteScratch(params.momentId)
      .then(res => {})
      .catch(err => console.log(err))
      .finally(() => this.getDraftData())
  }

  private addMoment(): void {
    this.$router.push({ name: 'momentEditor', query: { type: 'add' } }).catch(err => {})
  }

  private get showDot(): boolean {
    return this.filter.teacherName ||
      this.filter.homeRoomId.length ||
      this.filter.advisoryId ||
      this.filter.courseId.length ||
      this.filter.ccaCourseId ||
      this.checkedList.length
      ? true
      : false
  }

  private editMoment(moment, isDraft): void {
    let { momentId } = moment
    this.$router
      .push({ name: 'momentEditor', query: { type: 'edit', momentId, isDraft } })
      .catch(err => {})
  }

  private copyMoment(momentId): void {
    this.$router
      .push({ name: 'momentEditor', query: { type: 'copy&edit', momentId } })
      .catch(err => {})
  }

  private deleteMoment(momentId): void {
    this.$confirm({
      title: this.$t('common.deleteConfirm') as string,
      onOk: () => {
        MomentsController.delete(momentId).then(res => {
          this.$message.success(this.$tc('common.deleteSuccess'))
          this.refresh()
        })
      },
    })
  }

  private calcExpand(): void {
    this.$nextTick(() => {
      this.data.forEach((item, index) => {
        if (item.expandClickShow) return
        this.$set(item, 'expandClickShow', false)
        let el = document.getElementById(`momentTextContent_${index}`)
        if (!el) return
        let cw = el.clientWidth
        let sw = el.scrollWidth
        if (sw > cw) {
          this.$set(item, 'expandClickShow', true)
        }
      })
    })
  }

  private updated() {
    this.calcExpand()
  }

  private mounted() {
    // this.calcExpand()
    this.refresh()
    this.getDraftData()
    this.getDropDownInfo()
    let el = document.getElementById('moment_root') || ({} as any)
    el.onscroll = this.onScroll
  }

  public toTop(): void {
    let momentList = document.getElementById('moment_root')
    momentList ? (momentList.scrollTop = 0) : ''
  }

  private changeShareFlag(type, item): void {
    switch (type) {
      case 'parent':
        MomentsController.shareWithParent(!item.shareWithParents, item.momentId)
          .then(res => {
            item.shareWithParents = !item.shareWithParents
          })
          .catch(err => console.log(err))
          .finally(() => {})
        break
      case 'student':
        MomentsController.shareWithStudent(!item.shareWithStudents, item.momentId)
          .then(res => {
            item.shareWithStudents = !item.shareWithStudents
          })
          .catch(err => console.log(err))
          .finally(() => {})
    }
  }

  private avatarFilter(students: Array<any>): Array<any> {
    const newStudents = students.slice(0, 3)
    const avatars = newStudents.map(student => student.studentAvatarUrl)
    return avatars
  }

  private getDraftData(): void {
    MomentsController.getScratch()
      .then(res => {
        this.draftsData = res.data
      })
      .catch(err => console.log(err))
  }

  private refresh(): void {
    this.pagination.current = 0
    this.getAllDataFlag = false
    this.isTop = true
    this.data = []
    this.handleInfiniteOnLoad()
  }

  private clearFilter(): void {
    this.checkedList = ['student', 'parent', 'unshared']
    this.filter = {
      date: undefined,
      studentName: '',
      teacherName: '',
      homeRoomId: [],
      advisoryId: undefined,
      courseId: [],
      ccaCourseId: undefined,
    }
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private getDropDownInfo() {
    Promise.all([
      DropDownController.getClassList(),
      DropDownController.getHouseGroupList(),
      DropDownController.getCcaCourse(),
      CourseController.getCoursesOptionsCascade(),
    ]).then(res => {
      this.homeRooms = clearEmptyArray(res[0].data)
      this.advisories = res[1].data
      this.CCAClasses = res[2].data
      this.subjectClasses = clearEmptyArray(res[3].data)
    })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.clearFilter()
      this.refresh()
      this.getDraftData()
    }
  }

  private getIconResource(name): any {
    return require(`@/assets/images/OtherApp/Moment/${name}.svg`)
  }
}
