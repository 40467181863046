










































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import moment from 'moment'
import debounce from 'lodash/debounce'
import FlexTooltip from '@/components/FlexTooltip.vue'
import {
  CCACurriculumController,
  DropDownController,
  TeacherController,
} from '@/services/request.service'
import { i18n } from '@/i18n/i18n'
import { createMergeArray, guid, judgeVnode, toPage } from '@/utils/utils'
import { createSheet, exportXlsx, getBlob, writeFile } from '@/utils/xlsx'
import filterGroup from '@/components/filterGroup'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { createPagination } from '@/constant/constant'

const operations = {
  ccaExport: function(start, end) {
    return CCACurriculumController.getExportInfo('cca')
  },
  homeroomExport: function(start, end) {
    return CCACurriculumController.getExportInfo('class')
  },
  unenrollExport: function(start, end) {
    return CCACurriculumController.getUnEnrolled()
  },
  courseExport: function(start, end) {
    return CCACurriculumController.getExportStudentInfo()
  },
}

@Component({
  computed: {
    ...mapState({
      operationAuths: 'operationAuths',
    }),
  },
  components: {
    FlexTooltip,
    filterGroup,
  },
})
export default class CCAClass extends Vue {
  private exportLoading: any = {
    ccaLoading: false,
    homeroomLoading: false,
    unenrollLoading: false,
    courseLoading: false,
  }
  private filter: any = {
    name: '',
    date: undefined,
    campusIds: [],
    schoolYearId: undefined,
    dayOfWeeks: [],
    status: 0,
    sectionIds: [],
    courseFlag: 0,
    type: 0,
    chargeFlag: 0,
    teacherId: 0,
    classroomId: 0,
  }
  private loading: boolean = false
  private sections: any = []
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private classData: Array<any> = []
  private teachers: any = []
  private classrooms: any = []
  private sectionIds: any = []
  private campuses: any = []
  private schoolYears: any = []
  private type: string = ''
  private visible: boolean = false
  private form: any = {
    date: undefined,
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get weeks(): Array<any> {
    return [
      {
        label: this.$t('weekday.1'),
        value: 1,
      },
      {
        label: this.$t('weekday.2'),
        value: 2,
      },
      {
        label: this.$t('weekday.3'),
        value: 3,
      },
      {
        label: this.$t('weekday.4'),
        value: 4,
      },
      {
        label: this.$t('weekday.5'),
        value: 5,
      },
      // {
      //   label: this.$t('weekday.6'),
      //   value: 6,
      // },
      // {
      //   label: this.$t('weekday.7'),
      //   value: 7,
      // },
    ]
  }

  private get eCcaColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 10,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 10,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        width: 10,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 15,
      },
      {
        dataIndex: 'sectionName',
        title: this.$t('common.grade'),
        width: 15,
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 20,
      },
      {
        dataIndex: 'houseGroupName',
        title: this.$t('common.advisory'),
        width: 20,
      },
    ]
  }

  private get eHomeroomColumns(): Array<any> {
    return this.eCcaColumns.concat([
      {
        dataIndex: 'courseName',
        title: this.$t('router.CCAClass'),
        width: 20,
      },
      {
        dataIndex: 'teacherName',
        title: this.$t('common.teacher'),
        width: 20,
      },
      {
        dataIndex: 'classroom',
        title: this.$t('common.classroom'),
        width: 20,
      },
    ])
  }

  private get eCourseColumns(): Array<any> {
    return this.eCcaColumns.concat([
      {
        dataIndex: 'courseName',
        title: this.$t('common.weekday'),
        width: 20,
      },
      {
        dataIndex: 'Life Block',
        title: this.$t('common.teacher'),
        width: 20,
      },
      {
        dataIndex: 'classroom',
        title: this.$t('common.classroom'),
        width: 20,
      },
      {
        dataIndex: 'startDate',
        title: this.$t('common.startDate'),
        width: 20,
      },
      {
        dataIndex: 'endDate',
        title: this.$t('common.endDate'),
        width: 20,
      },
      {
        dataIndex: 'Teacher',
        title: this.$t('common.teacher'),
        width: 20,
      },
    ])
  }

  private get selfId(): any {
    return this.$store.state.memberId
  }

  private get formatSections(): any {
    return this.sections.map(item => {
      return {
        children: item.subOptions.map(child => ({
          key: child.key,
          title: this.locale === 'zh' ? child.value : child.enValue || child.value,
          value: child.key,
        })),
        title: `${item.value}-${item.enValue}`,
        key: `${item.enValue}_${item.extraValue}_${item.key}`,
        value: `${item.enValue}_${item.extraValue}_${item.key}`,
      }
    })
  }

  private get chargeFlags(): Array<any> {
    return [
      {
        label: this.$t('CCAClass.yes'),
        value: 1,
      },
      {
        label: this.$t('CCAClass.no'),
        value: 2,
      },
    ]
  }

  private get columns(): Array<Object> {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        title: this.$t('CCAClass.name'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'classTime',
        title: this.$t('CCAClass.classTime'),
        scopedSlots: { customRender: 'classTime' },
      },
      {
        dataIndex: 'campuses',
        key: 'campuses',
        title: this.$t('common.campus'),
        scopedSlots: { customRender: 'enrollRange' },
        ellipsis: true,
      },
      {
        dataIndex: 'teacherNames',
        key: 'teacherNames',
        title: this.$t('CCAClass.teacherNames'),
        ellipsis: true,
        scopedSlots: { customRender: 'teacherNames' },
      },
      {
        dataIndex: 'classRoomName',
        key: 'classroom',
        title: this.$t('CCAClass.classroom'),
        width: 100,
        ellipsis: true,
        scopedSlots: { customRender: 'classroom' },
      },
      {
        dataIndex: 'chargeFlag',
        key: 'isCharge',
        title: this.$t('CCAClass.isCharge'),
        width: 90,
        ellipsis: true,
        scopedSlots: { customRender: 'isCharge' },
      },
      {
        dataIndex: 'enrollTime',
        key: 'enrollTime',
        title: this.$t('CCAClass.enrollTime'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'enrollTime' },
      },
      {
        dataIndex: 'enrolledNum',
        key: 'enrolledNum',
        title: this.$t('CCAClass.enrolledNum'),
        align: 'center',
        width: 100,
        scopedSlots: { customRender: 'enrolledNum' },
      },
      {
        dataIndex: 'status',
        key: 'status',
        title: this.$t('CCAClass.status'),
        width: 120,
        scopedSlots: { customRender: 'status' },
      },
    ]
  }
  private get status(): Array<any> {
    return [
      {
        label: this.$t('staticCode.1001'),
        value: '1001',
      },
      {
        label: this.$t('staticCode.1002'),
        value: '1002',
      },
      // {
      //   label: this.$t('staticCode.1003'),
      //   value: '1003'
      // },
      {
        label: this.$t('staticCode.1004'),
        value: '1004',
      },
    ]
  }

  private get eAllColumns(): Array<any> {
    return [
      {
        dataIndex: 'name',
        title: this.$t('CCAClass.name'),
        width: 50,
      },
      {
        dataIndex: 'classTime',
        title: this.$t('CCAClass.classTime'),
        width: 40,
      },
      {
        dataIndex: 'campuses',
        title: this.$t('common.campus'),
        width: 20,
      },
      {
        dataIndex: 'teacherNames',
        title: this.$t('CCAClass.teacherNames'),
        width: 40,
      },
      {
        dataIndex: 'classRoomName',
        title: this.$t('CCAClass.classroom'),
        width: 30,
      },
      {
        dataIndex: 'chargeFlag',
        title: this.$t('CCAClass.isCharge'),
        width: 20,
      },
      {
        dataIndex: 'enrollTime',
        title: this.$t('CCAClass.enrollTime'),
        width: 30,
      },
      {
        dataIndex: 'enrolledNum',
        title: this.$t('CCAClass.enrolledNum'),
        width: 15,
      },
    ]
  }

  private get showChild(): boolean {
    return this.$route.name !== 'CCAClass'
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private async exportStudents(type): Promise<void> {
    if (!type) {
      const form = this.$refs.form as any
      form.validate(valid => {
        if (!valid) {
          return
        }
      })
    }
    type = type || this.type
    const start =
      type === 'cca'
        ? moment()
            .startOf('day')
            .valueOf()
        : this.form.date.startOf('isoWeek').valueOf()
    const end =
      type === 'cca'
        ? moment()
            .endOf('day')
            .valueOf()
        : this.form.date.endOf('isoWeek').valueOf()
    this.exportLoading[`${type}Loading`] = true
    operations[`${type}Export`](start, end)
      .then(async res => {
        if (type !== 'unenroll' && type !== 'course') {
          const ccaList = res.data
          const zip = new JSZip()
          ccaList.forEach(cca => {
            let workbook: any = null
            cca.dayList.forEach(day => {
              const student = day.studentList
              workbook = createSheet(
                type === 'cca' ? this.eCcaColumns : this.eHomeroomColumns,
                student,
                workbook,
                type === 'cca' ? 'sheet1' : this.$t(`weekday.${day.dayOfWeek}`)
              )
            })
            workbook.eachSheet(sheet => {
              sheet.getRow(1).eachCell(cell => {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'darkTrellis',
                  fgColor: { argb: 'FFE9E9E9' },
                  bgColor: { argb: 'FFE9E9E9' },
                } as any
              })
              sheet.eachRow(row => {
                row.height = 30
                row.eachCell(cell => {
                  cell.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } },
                  }
                  cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
                })
              })
            })
            if (type === 'cca') {
              const ws = workbook.getWorksheet(1)
              const teacherName = cca.excelName
              const title = `${this.$t(`router.CCAClass`)}: ${teacherName}`

              ws.insertRow(1, [title])
              ws.mergeCells(1, 1, 1, 8)
              ws.eachRow(row => {
                row.height = 30
                row.eachCell(cell => {
                  cell.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } },
                  }
                  cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
                })
              })
            }
            const blob = getBlob(workbook)
            zip.file(`${cca.excelName}.xlsx`, blob, { binary: true }) // 逐个添加文件
          })
          zip.generateAsync({ type: 'blob' }).then(content => {
            // 生成二进制流
            saveAs(content, `Life_Block_${type === 'homeroom' ? 'Student' : 'Course'}_Enroll_Excel`) // 利用file-saver保存文件
            this.exportLoading[`${type}Loading`] = false
          })
        } else if (type === 'course') {
          const campuses = res.data
          const zip = new JSZip()
          const weekDayStr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri']
          campuses.forEach(campus => {
            let formData = []
            campus.data.forEach(item => {
              formData = formData.concat(
                item.dayOfWeeks.map(weekday => ({
                  ...item,
                  startDate: moment(item.timeStart).format('YYYY.MM.DD HH:mm'),
                  endDate: moment(item.timeEnd).format('YYYY.MM.DD HH:mm'),
                  weekday: weekDayStr[weekday] || '',
                }))
              )
            })
            const file = exportXlsx(this.eCourseColumns, formData)
            file
              .getWorksheet(1)
              .getRow(1)
              .eachCell(cell => {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'darkTrellis',
                  fgColor: { argb: 'FFE9E9E9' },
                  bgColor: { argb: 'FFE9E9E9' },
                } as any
              })
            file.getWorksheet(1).eachRow(row => {
              row.height = 30
              row.eachCell(cell => {
                cell.border = {
                  top: { style: 'thin', color: { argb: 'FF000000' } },
                  left: { style: 'thin', color: { argb: 'FF000000' } },
                  bottom: { style: 'thin', color: { argb: 'FF000000' } },
                  right: { style: 'thin', color: { argb: 'FF000000' } },
                }
                cell.style.alignment = { vertical: 'middle' }
              })
            })
            const blob = getBlob(file)
            zip.file(
              `${
                this.locale === 'zh' ? campus.campusName : campus.campusEnName || campus.campusName
              }_Life Block.xlsx`,
              blob,
              { binary: true }
            ) // 逐个添加文件
          })
          zip.generateAsync({ type: 'blob' }).then(content => {
            // 生成二进制流
            saveAs(content, `Life_Block_Student_Course_Excel`) // 利用file-saver保存文件
            this.exportLoading[`${type}Loading`] = false
          })
        } else {
          let workbook: any = null
          const cca = res.data
          cca.forEach(day => {
            const student = day.studentList
            workbook = createSheet(
              this.eCcaColumns,
              student,
              workbook,
              this.$t(`weekday.${day.dayOfWeek}`)
            )
          })
          workbook.eachSheet(sheet => {
            sheet.getRow(1).eachCell(cell => {
              cell.fill = {
                type: 'pattern',
                pattern: 'darkTrellis',
                fgColor: { argb: 'FFE9E9E9' },
                bgColor: { argb: 'FFE9E9E9' },
              } as any
            })
            sheet.eachRow(row => {
              row.height = 30
              row.eachCell(cell => {
                cell.border = {
                  top: { style: 'thin', color: { argb: 'FF000000' } },
                  left: { style: 'thin', color: { argb: 'FF000000' } },
                  bottom: { style: 'thin', color: { argb: 'FF000000' } },
                  right: { style: 'thin', color: { argb: 'FF000000' } },
                }
                cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
              })
            })
          })
          await writeFile(workbook, 'Unenrolled Student.xlsx')
          this.exportLoading[`${type}Loading`] = false
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.exportLoading[`${type}Loading`] = false
        this.visible = false
      })
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    this.loading = true
    let condition = {
      // pageCurrent: page.current,
      // pageSize: page.pageSize,
      // name: this.filter.name ? this.filter.name.trim() : undefined,
      // status: this.filter.status || undefined,
      // dayOfWeeks: this.filter.classTime,
      // sectionIds: this.filter.sectionIds,
      // chargeFlag: this.filter.chargeFlag === 2 ? false : this.filter.chargeFlag === 1 ? true : undefined,
      // teacherId: this.filter.teacherId || undefined,
      // classroomId: this.filter.classroomId || undefined,
      // studentName: this.filter.studentName.trim() || undefined
      pageCurrent: page.current,
      pageSize: page.pageSize,
      name: this.filter.name === '' ? undefined : this.filter.name.trim(),
      date: this.filter.date ? this.filter.date.valueOf() : undefined,
      campusIds: this.filter.campusIds.length ? this.filter.campusIds : undefined,
      schoolYearId: this.filter.schoolYearId ? this.filter.schoolYearId : undefined,
      dayOfWeeks: this.filter.dayOfWeeks.length ? this.filter.dayOfWeeks : undefined,
      status: this.filter.status === 0 ? undefined : this.filter.status,
      sectionIds: this.filter.sectionIds,
      courseFlag: this.filter.courseFlag
        ? this.filter.courseFlag === 1
          ? true
          : false
        : undefined,
      type: this.filter.type ? this.filter.type : undefined,
      chargeFlag:
        this.filter.chargeFlag === 2 ? false : this.filter.chargeFlag === 1 ? true : undefined,
      teacherId: this.filter.teacherId || undefined,
      classroomId: this.filter.classroomId || undefined,
    }
    this.classData = []
    CCACurriculumController.list(condition)
      .then(res => {
        this.classData = res.data.items
        this.classData.map(item => {
          let enrollTimeStart = moment(item.applyTimeStart).format('MM[.]DD HH:mm')
          let enrollTimeEnd = moment(item.applyTimeEnd).format('MM[.]DD HH:mm')
          this.$set(item, 'enrollTime', `${enrollTimeStart} ~ ${enrollTimeEnd}`)
          // if(!item.classRoomName){
          //   item.classRoomName = this.$t('CCAClass.noClassroom')
          // }
        })
        const { pageSize, pageCurrent, totalItem } = res.data
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.classData, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getTimeString(timeItem): string {
    const time = Vue.filter('doubleMoment')([timeItem.timeStart, timeItem.timeEnd], 'YYYY.MM.DD')
    let dayOfWeeks = timeItem.dayOfWeeks.map(day => {
      return this.$t(`weekday.${day}`)
    })
    dayOfWeeks = dayOfWeeks.join('、')
    return `${time} ${dayOfWeeks}`
  }

  private exportAllCourse(): void {
    this.exportLoading.allLoading = true
    let condition = {
      pageCurrent: 1,
      pageSize: 0,
      name: this.filter.name === '' ? undefined : this.filter.name.trim(),
      date: this.filter.date ? this.filter.date.valueOf() : undefined,
      campusIds: this.filter.campusIds.length ? this.filter.campusIds : undefined,
      schoolYearId: this.filter.schoolYearId ? this.filter.schoolYearId : undefined,
      dayOfWeeks: this.filter.dayOfWeeks.length ? this.filter.dayOfWeeks : undefined,
      status: this.filter.status === 0 ? undefined : this.filter.status,
      sectionIds: this.filter.sectionIds,
      courseFlag: this.filter.courseFlag
        ? this.filter.courseFlag === 1
          ? true
          : false
        : undefined,
      type: this.filter.type ? this.filter.type : undefined,
      chargeFlag:
        this.filter.chargeFlag === 2 ? false : this.filter.chargeFlag === 1 ? true : undefined,
      teacherId: this.filter.teacherId || undefined,
      classroomId: this.filter.classroomId || undefined,
      // studentName: this.filter.studentName.trim() || undefined
    }

    CCACurriculumController.list(condition)
      .then(async res => {
        const dataList = res.data.items.map(item => {
          let enrollTimeStart = moment(item.applyTimeStart).format('MM[.]DD HH:mm')
          let enrollTimeEnd = moment(item.applyTimeEnd).format('MM[.]DD HH:mm')
          return {
            ...item,
            classTime: item.timeList.map(timeItem => this.getTimeString(timeItem)).join('、'),
            teacherNames: item.teacherNames.join('、'),
            type:
              parseInt(item.type, 10) === 1001
                ? this.$t('CCAClass.schoolCourse')
                : this.$t('CCAClass.outOfSchool'),
            enrollTime: `${enrollTimeStart} ~ ${enrollTimeEnd}`,
            enrolledNum: `${item.num || 0}/${item.numMax || 0}`,
            chargeFlag: item.chargeFlag ? this.$t('common.true') : this.$t('common.false'),
          }
        })
        const file = exportXlsx(this.eAllColumns, dataList)
        const worksheet = file.getWorksheet(1)

        worksheet.eachRow(row => {
          row.height = 30
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })

        const schoolYearName = this.schoolYears.find(item => item.key === this.filter.schoolYearId)
          .value

        await writeFile(file, `${schoolYearName}Life Block.xlsx`)

        this.exportLoading.allLoading = false
      })
      .catch(err => {
        console.log(err)
        this.exportLoading.allLoading = false
      })
  }

  private viewDetails(classId, status): void {
    this.$router
      .push({ name: 'CCAClassDetail', params: { classId }, query: { status } })
      .catch(err => {})
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(pagination)
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private created() {
    this.getDropDownInfo()
  }

  private onSectionChange(value, label, extra) {
    const isVNode = judgeVnode(extra.triggerNode)
    this.filter.sectionIds = (extra.allCheckedNodes || [])
      .map(item => {
        if (!isVNode) {
          if (item.children) {
            return item.children.map(item => item.node.key)
          } else {
            return item.node.key
          }
        } else {
          if (item.componentOptions.children.length) {
            return item.componentOptions.children.map(item => item.data.key)
          } else {
            return item.data.key
          }
        }
      })
      .flat()
    this.fetchData()
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getSectionCascade(),
      TeacherController.getListAll(),
      DropDownController.getClassRoomCascade(),
      DropDownController.getCampusList(),
      DropDownController.getSchoolYearList(),
    ])
      .then(res => {
        this.sections = res[0].data

        this.teachers = res[1].data as any

        let classrooms = []
        res[2].data.forEach(item => {
          createMergeArray(item, classrooms)
        })
        this.classrooms = classrooms
        this.campuses = res[3].data
        this.schoolYears = res[4].data
        this.filter.schoolYearId = this.schoolYears[0].key
        this.getData()
      })
      .catch(err => {})
  }

  private reset(): void {
    this.filter = {
      name: '',
      date: undefined,
      campusIds: [],
      schoolYearId: this.schoolYears[0].key,
      dayOfWeeks: [],
      status: 0,
      sectionIds: [],
      courseFlag: 0,
      type: 0,
      chargeFlag: 0,
      teacherId: 0,
      classroomId: 0,
    }
    this.sectionIds = []
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private showWeekSelect(type): void {
    this.type = type
    this.visible = true
  }
}
