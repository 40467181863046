




























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class CCAClassDetail extends Vue {
  // 接受父组件的值
  @Prop({ default: () => [] }) private readonly data!: Array<any>
  @Prop() private readonly loading!: boolean
  @Prop() private readonly align!: string
  @Prop({ default: 0 }) private readonly total!: number

  private moment = moment

  private get columns(): Array<Object> {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        title: this.$t('CCAClass.studentName'),
        align: this.align,
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'studentNum',
        key: 'studentId',
        title: this.$t('CCAClass.studentId'),
        scopedSlots: { customRender: 'studentId' },
        align: this.align,
      },
      {
        dataIndex: 'sectionName',
        key: 'grade',
        title: this.$t('CCAClass.grade'),
        scopedSlots: { customRender: 'grade' },
        align: this.align,
      },
      {
        dataIndex: 'className',
        key: 'mainClass',
        title: this.$t('CCAClass.mainClass'),
        align: this.align,
        scopedSlots: { customRender: 'mainClass' },
      },
      {
        dataIndex: 'houseGroupName',
        key: 'college',
        title: this.$t('CCAClass.college'),
        align: this.align,
        scopedSlots: { customRender: 'college' },
      },
    ]
  }

  private calcDate(status, date): any {
    const formatDate = moment(date).format('YYYY-MM-DD')
    return this.$t(`myClass.${status === '1017' ? '1017' : '1018'}`, { date: formatDate })
  }
}
