





























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { DropDownController, AddressBookController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { guid, judgeVnode, toPage, getSchoolInfo } from '@/utils/utils'
import { createPagination } from '@/constant/constant'
import TableTotal from '@/components/TableTotal.vue'
import Transfer from '@/components/Transfer.vue'
import { exportXlsx, writeFile } from '@/utils/xlsx'

@Component({
  components: {
    FlexTooltip,
    TableTotal,
    Transfer,
  },
})
export default class AddressBook extends Vue {
  private searchText = ''
  private rootSubmenuKeys: any = ['sub1']
  private menuKeys: any = []

  private departmentList: any = []
  private openKeys: any = ['sub1']
  private pagination: any = createPagination({ pageSize: 30 })
  private selectedRowKeys: Array<any> = []
  private data: Array<any> = []
  private loading: boolean = false
  private introduction: any = {}

  private exportLoading: boolean = false

  private get selectedSchool(): any {
    const schooInfo = getSchoolInfo()
    return schooInfo
  }

  private get showChild(): boolean {
    return this.$route.name !== 'addressBook'
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get memberType(): any {
    return {
      '2003': this.$t('addressBook.staff'),
      '2004': this.$t('addressBook.externalStaff'),
    }
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('addressBook.name'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
        width: 400 * this.ratio,
      },
      {
        dataIndex: 'type',
        key: 'type',
        title: this.$t('addressBook.type'),
        scopedSlots: { customRender: 'type' },
        // width: 100 * this.ratio,
        ellipsis: true,
      },
      {
        dataIndex: 'gender',
        key: 'gender',
        title: this.$t('addressBook.gender'),
        scopedSlots: { customRender: 'gender' },
        // width: 100 * this.ratio,
        ellipsis: true,
      },
      {
        dataIndex: 'position',
        ellipsis: true,
        title: this.$t('addressBook.position'),
      },
      {
        dataIndex: 'email',
        ellipsis: true,
        title: this.$t('addressBook.email'),
        scopedSlots: { customRender: 'email' },
        width: 230 * this.ratio,
      },
    ].filter(item => item)
  }

  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'fullname',
        title: this.$t('addressBook.name'),
        width: 30,
      },
      {
        dataIndex: 'type',
        title: this.$t('addressBook.type'),
        width: 15,
      },
      {
        dataIndex: 'gender',
        title: this.$t('addressBook.gender'),
        width: 10,
      },
      {
        dataIndex: 'position',
        title: this.$t('addressBook.position'),
        width: 15,
      },
      {
        dataIndex: 'email',
        title: this.$t('addressBook.email'),
        width: 30,
      },
    ]
  }

  /**
   * 搜索框功能
   */
  private onSearch(): void {
    // this.searchText = value.target.value
    this.getDropDownInfo()
  }
  private fetchSearch = debounce(() => {
    // this.getData()
    this.onSearch()
  }, 500)

  private viewDetails(data): void {
    this.$router
      .push({ name: 'staffDetail', query: { teacherId: data.personId, type: data.type } })
      .catch(err => {})
  }

  private toExport(): void {
    this.exportLoading = true
    AddressBookController.addressBookList({
      pageCurrent: 1,
      pageSize: 0,
      depId: parseInt(this.menuKeys[0], 10),
    })
      .then(async res => {
        const staffs = res.data.items.map(item => ({
          ...item,
          fullname: (item.enName + ' ' + item.name).trim(),
          gender: item.gender ? this.$t(`common.gender.${item.gender}`) : '',
          type: this.memberType[item.type],
        }))
        const file = exportXlsx(this.excelColumns, staffs)
        const worksheet = file.getWorksheet(1)

        worksheet.insertRow(1, [this.introduction.name])
        worksheet.insertRow(2, [])
        worksheet.insertRow(3, [
          this.$t('addressBook.introduction') + ':',
          this.introduction.introduction,
        ])
        worksheet.insertRow(4, [
          this.$t('addressBook.contactEmail') + ':',
          this.introduction.email,
          '',
          this.$t('addressBook.contactEmail') + ':',
          this.introduction.mobile,
        ])
        worksheet.mergeCells('A1:E2')
        worksheet.mergeCells('B3:E3')
        worksheet.mergeCells('B4:C4')

        worksheet.getCell('E4').border = {
          right: { style: 'thin', color: { argb: 'FF000000' } },
        }
        worksheet.eachRow(row => {
          row.height = 30
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })

        await writeFile(file, `${this.introduction.name}AddressBook.xlsx`)

        this.exportLoading = false
      })
      .catch(err => {
        console.log(err)
        this.exportLoading = false
      })
  }

  private copyEmail(text): void {
    // const text = res.data[2002] + '/courseSelection?csqId=' + id
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', text)
    input.select()
    // ios兼容
    input.setSelectionRange(0, text.length)
    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    }
    document.body.removeChild(input)
    this.$message.success(this.$tc('common.copySuccess'))
  }

  private changeMenu({ item, key, keyPath }): void {
    if (key) {
      this.menuKeys = [key]
      this.getData()
    }
  }

  /**
   * 表格相关
   */
  private clearSelection(): void {
    this.selectedRowKeys = []
  }

  private onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private onOpenChange(openKeys): void {
    const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.openKeys = openKeys
    } else {
      this.openKeys = latestOpenKey ? [latestOpenKey] : []
    }
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    if (this.loading) return
    //   const { sectionIds } = this.filter
    this.data = []
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      name: this.searchText === '' ? undefined : this.searchText.trim(),
      depId: parseInt(this.menuKeys[0], 10),
    }
    this.loading = true
    Promise.all([
      AddressBookController.addressBookList(condition),
      AddressBookController.departmentByDepId(condition.depId),
    ])
      .then(res => {
        this.data = res[0].data.items
        this.pagination.total = res[0].data.totalItem

        /**
         * test
         */
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })

        this.introduction = res[1].data
        // this.data.forEach((item) => {
        //   this.$set(this.displayMap, item.depPersonId, item.display)
        // })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getDropDownInfo(): void {
    let condition = {
      name: this.searchText === '' ? undefined : this.searchText.trim(),
    }
    AddressBookController.departmentList(condition)
      .then(res => {
        // this.sections = res[0].data
        this.departmentList = res.data
        const depIds = res.data.map(item => item.depId.toString())

        // this.menuKeys = this.menuKeys.length ? this.menuKeys : this.departmentList[0]?.depId ? [this.departmentList[0]?.depId.toString()] : []
        this.menuKeys =
          this.menuKeys.length && depIds.includes(this.menuKeys[0])
            ? this.menuKeys
            : this.departmentList[0]?.depId
            ? [this.departmentList[0]?.depId.toString()]
            : []

        // this.$forceUpdate
        if (this.departmentList.length) {
          this.getData()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    // this.refresh()
    this.getDropDownInfo()
  }

  // @Watch('menuKeys', { immediate: true })
  // private onMenuKeyChange(newVal): void {
  //   if(newVal){
  //     this.$nextTick(() => {

  //       const menuDom = this.$refs['menu-' + newVal[0]][0] as any
  //       debugger
  //       menuDom.scrollIntoView(true)
  //       debugger
  //     })
  //   }
  // }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)
}
