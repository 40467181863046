






































































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import enrollTable from './enrollTable.vue'
import { mapState } from 'vuex'
import moment from 'moment'
import { CCACurriculumController } from '@/services/request.service'

@Component({
  components: {
    enrollTable,
  },
  computed: {
    ...mapState({
      operationAuths: 'operationAuths',
    }),
  },
})
export default class CCAClassDetail extends Vue {
  private enrolledData: Array<any> = []
  private classInfo: any = {}
  private enrolledDataLoading: boolean = false
  private classId: number = 0
  private status: any
  private tableTotal: number = 0

  private get teacherNames(): string {
    return this.classInfo?.teachers
      ?.map(item => {
        return (item.enName.length ? item.enName : '' + ' ' + item.name).trim()
      })
      .join('、')
  }

  private get classroom(): string {
    return this.classInfo.classRoomName
  }

  private get infoList(): Array<any> {
    let data = this.dealBaseData(this.classInfo)
    return [
      {
        key: 'schoolYear',
        label: this.$t('CCAClass.schoolYear'),
        value: data.schoolYearName,
      },
      {
        key: 'houseGroups',
        label: this.$t('common.campus'),
        value: data.houseGroups,
      },
      {
        key: 'courseFlag',
        label: this.$t('CCAClass.courseFlag'),
        value: this.$t(`common.${data.courseFlag || false}`),
      },
      data.courseFlag && [
        {
          key: 'courseType',
          label: this.$t('CCAClass.teachingType'),
          value: data.kind,
        },
        {
          key: 'credit',
          label: this.$t('CCAClass.credits'),
          value: data.credits,
        },
      ],
      {
        key: 'type',
        label: this.$t('CCAClass.courseType'),
        value: data.type,
      },
      {
        key: 'fee',
        label: this.$t('CCAClass.isCharge'),
        value: data.chargeFlag ? this.$t('CCAClass.yes') : this.$t('CCAClass.no'),
      },
      data.chargeFlag && {
        key: 'feeQuote',
        label: this.$t('CCAClass.chargeQuote'),
        value: data.feeDescription,
      },
    ]
      .flat()
      .filter(item => item)
  }
  private get weeks(): Array<any> {
    return [
      {
        label: this.$t('weekday.monday'),
        value: 1,
      },
      {
        label: this.$t('weekday.tuesday'),
        value: 2,
      },
      {
        label: this.$t('weekday.wednesday'),
        value: 3,
      },
      {
        label: this.$t('weekday.thursday'),
        value: 4,
      },
      {
        label: this.$t('weekday.friday'),
        value: 5,
      },
    ]
  }
  private get locale(): string {
    return this.$store.state.locale
  }
  private get remainNum(): number {
    return this.classInfo.numMax - this.classInfo.num || 0
  }

  private getClassInfoData(classId): void {
    this.classInfo = {}
    CCACurriculumController.detail(classId)
      .then(res => {
        this.classInfo = res.data
      })
      .catch(err => console.log(err))
  }

  private dealDetailData(data): any {
    if (!Object.keys(data).length)
      return {
        schoolYearName: '',
        enrollTime: '',
        numMax: 0,
        numMin: 0,
        enrollRange: '',
        classRoomName: '',
        teachers: '',
        classTime: '',
        cycle: '',
      }
    const timeObj = {}
    data.timeList.forEach((item, index) => {
      const time = Vue.filter('doubleMoment')([item.timeStart, item.timeEnd], 'YYYY.MM.DD')
      let dayOfWeeks = item.dayOfWeeks.map(day => {
        return this.$t(`weekday.${day}`)
      })
      dayOfWeeks = dayOfWeeks.join('、')
      this.$set(
        timeObj,
        'classTime' + index,
        `${time} ${dayOfWeeks} ${this.$t('staticCode.' + item.repeatType)}`
      )
    })

    // const weekDay = data.dayOfWeeks.map(day => this.$t(`weekday.${day}`))
    // let classTime = `${weekDay} ${data.classPeriod.description}`
    let enrollTime = `${moment(data.applyTimeStart).format('YYYY[.]MM[.]DD HH:mm')}~${moment(
      data.applyTimeEnd
    ).format('YYYY[.]MM[.]DD HH:mm')}`
    // let cycle = `${moment(data.timeStart).format('YYYY[.]MM[.]DD')}~${moment(data.timeEnd).format('YYYY[.]MM[.]DD')} ${this.$t('staticCode.'+data.repeatType)}`
    let teachers = data.teachers
      ? data.teachers.map(item => `${item.enName} ${item.name}`).join('、')
      : ''
    let enrollGrade = data.targetSections
      ? data.targetSections.map(item => item.name).join('、')
      : ''
    let enrollCollege = data.houseGroups ? data.houseGroups.map(item => item.name).join('、') : ''
    let gradeString = enrollGrade === '' ? '' : `${this.$t('common.grade')}：${enrollGrade}`
    let collegeString =
      enrollCollege === '' ? '' : `${this.$t('common.advisory')}：${enrollCollege}`
    let enrollRange = gradeString + (gradeString === '' ? '' : '; ') + collegeString
    return {
      ...data,
      enrollTime,
      // cycle,
      teachers,
      enrollRange,
      // classTime,
      ...timeObj,
    }
  }

  private dealBaseData(data): any {
    if (!Object.keys(data).length)
      return {
        schoolYearName: '',
        houseGroups: [],
        courseFlag: false,
        kind: '',
        credits: 0,
        type: '',
        chargeFlag: false,
        feeDescription: '',
      }

    const { schoolYearName, courseFlag, kind, credits, feeDescription, chargeFlag } = data
    // let courseFlag = data.courseFlag ? this.$t('common.true') : this.$t('common.true')
    // let chargeFlag = data.chargeFlag ? this.$t('common.true') : this.$t('common.true')
    let type = data.type.length
      ? data.type === '1001'
        ? this.$t('CCAClass.schoolCourse')
        : this.$t('CCAClass.outOfSchool')
      : ''
    // let houseGroups = data.houseGroups.length ? data.houseGroups.map((item) => item.name).join('、') : ''
    let houseGroups = data.campuses.length
      ? data.campuses.map(item => (this.locale === 'zh' ? item.name : item.enName)).join('、')
      : ''

    return {
      schoolYearName,
      houseGroups,
      courseFlag,
      kind,
      credits,
      type,
      chargeFlag,
      feeDescription,
    }
  }

  private dealCourseData(data): any {
    if (!data?.timeList?.length) return []

    const timeArr: any = []
    data.timeList.forEach((item, index) => {
      const time = Vue.filter('doubleMoment')([item.timeStart, item.timeEnd], 'YYYY.MM.DD')
      let dayOfWeeks = item.dayOfWeeks.map(day => {
        return this.$t(`weekday.${day}`)
      })
      dayOfWeeks = dayOfWeeks.join('、')
      // this.$set(timeObj,'classTime' + index, `${time} ${dayOfWeeks} ${this.$t('staticCode.'+item.repeatType)}`)
      timeArr.push({
        time,
        dayOfWeeks,
        repeatType: this.$t('staticCode.' + item.repeatType),
      })
    })

    return timeArr
  }

  private dealEnrolmentData(data): any {
    if (!Object.keys(data).length)
      return {
        // forSelection: false,
        enrollTime: '',
        enrollRange: '',
        numMin: 0,
        numMax: 0,
      }
    const { numMin, numMax } = data
    // let forSelection = data.forSelection ? this.$t('common.true') : this.$t('common.true')

    let enrollGrade = data.targetSections
      ? data.targetSections.map(item => item.name).join('、')
      : ''
    let enrollCollege = data.houseGroups ? data.houseGroups.map(item => item.name).join('、') : ''

    // let gradeString = enrollGrade === '' ? '' : `${this.$t('common.grade')}：${enrollGrade}`
    let gradeString = enrollGrade === '' ? '' : `${enrollGrade}`
    let collegeString =
      enrollCollege === '' ? '' : `${this.$t('common.advisory')}：${enrollCollege}`

    let enrollTime = `${moment(data.applyTimeStart).format('YYYY[.]MM[.]DD HH:mm')}~${moment(
      data.applyTimeEnd
    ).format('YYYY[.]MM[.]DD HH:mm')}`
    // let enrollRange = gradeString + (gradeString === '' ? '' : '; ') + collegeString
    let enrollRange = gradeString

    return {
      // forSelection,
      enrollTime,
      enrollRange,
      numMin,
      numMax,
    }
  }

  private getEnrolledData(page = { current: 1, pageSize: 50 }) {
    this.enrolledData = []
    this.enrolledDataLoading = true
    CCACurriculumController.getApplyInfo(this.classId)
      .then(res => {
        this.enrolledData = res.data || []
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.enrolledDataLoading = false
      })
  }

  private getRefWidth(key) {
    return this.$refs[key][0].clientWidth
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.classId = to.params.classId
    this.status = to.query.status
    this.getClassInfoData(to.params.classId)
    this.getEnrolledData()
  }
}
