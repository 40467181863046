































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class DraftModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly data!: any

  private moment = moment
  private document = document

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }

  private deleteDraft(momentId): void {
    this.$emit('delete', { momentId })
  }

  private view(momentId): void {
    this.$emit('view', { momentId }, true)
    this.closeModal()
  }
}
