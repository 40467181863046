var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"leave-message-modal",attrs:{"visible":_vm.visible,"title":(_vm.info || {}).title,"destroyOnClose":"","centered":"","maskClosable":false,"width":480},on:{"cancel":_vm.closeModal,"ok":_vm.confirm}},[((_vm.info || {}).tips)?_c('a-icon',{staticClass:"color-style",staticStyle:{"padding-top":"16px"},attrs:{"type":"info-circle","theme":"filled"}}):_vm._e(),_c('div',{staticClass:"modal-content",style:({ paddingTop: _vm.info.tips ? '16px' : '0px' })},[_c('div',{staticClass:"content-title"},[_vm._v(" "+_vm._s(_vm.info.tips)+" ")]),_c('a-form',{attrs:{"form":_vm.rejectForm,"autocomplete":"nope"}},[(_vm.info.type === 'pass')?_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('leaveApproval.leaveType')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('leaveApproval.leaveType'),
                } ],
            } ]),expression:"[\n            'type',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('leaveApproval.leaveType'),\n                },\n              ],\n            },\n          ]"}],staticClass:"filter-input",attrs:{"placeholder":_vm.$t('common.select')}},_vm._l((_vm.leaveTypes),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(item.label))])}),1)],1):_vm._e(),(_vm.info.type === 'pass')?_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('leaveApproval.leaveTime')}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'leaveTime',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('tips.selectDate'),
                } ],
            } ]),expression:"[\n            'leaveTime',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('tips.selectDate'),\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","showTime":{
            format: 'HH:mm',
          },"disabledDate":_vm.disabledDate,"getCalendarContainer":function () { return _vm.$document.body; }}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":(_vm.info || {}).itemLabel}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'declinedReason',
            {
              rules: [
                _vm.info.type === 'reject'
                  ? {
                      required: true,
                      message: _vm.$t('placeholder.enter'),
                    }
                  : undefined,
                {
                  max: 2000,
                  message: _vm.$t('common.textLimit2000'),
                } ].filter(function (item) { return item; }),
            } ]),expression:"[\n            'declinedReason',\n            {\n              rules: [\n                info.type === 'reject'\n                  ? {\n                      required: true,\n                      message: $t('placeholder.enter'),\n                    }\n                  : undefined,\n                {\n                  max: 2000,\n                  message: $t('common.textLimit2000'),\n                },\n              ].filter(item => item),\n            },\n          ]"}],attrs:{"placeholder":_vm.$t('common.enter'),"autoSize":{
            minRows: 4,
            maxRows: 10,
          }}})],1),(_vm.info.type === 'pass')?_c('a-form-item',{attrs:{"label":_vm.$t('diary.emailNoticeTeacher')}},[(_vm.selectedTeachers.length)?_c('div',{staticClass:"tag-container"},_vm._l((_vm.selectedTeachers),function(item){return _c('a-tag',{key:'teacher' + item.id,attrs:{"closable":true},on:{"close":function($event){return _vm.removeSelectedTeacher(item.id)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e(),_c('a-button',{attrs:{"block":"","type":"dashed","icon":"plus","loading":_vm.teacherLoading},on:{"click":_vm.addTeacher}},[_vm._v(_vm._s(_vm.$t('diary.addTeacher')))])],1):_vm._e()],1)],1),_c('group-transfer',{attrs:{"title":_vm.transferTitle,"showTab":"","defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedDataTeacher},model:{value:(_vm.transferVisible),callback:function ($$v) {_vm.transferVisible=$$v},expression:"transferVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }