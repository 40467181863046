














import { Component, Vue, Watch } from 'vue-property-decorator'
import ApprovalViewer from './components/ApprovalViewer.vue'

@Component({
  components: {
    ApprovalViewer,
  },
})
export default class LeaveApproval extends Vue {
  private get isChildRoute(): boolean {
    return this.$route.name !== 'leaveApproval'
  }

  private get menuList(): Array<any> {
    return [
      {
        key: 'approving',
        label: this.$t('leaveApproval.approving'),
      },
      {
        key: 'approved',
        label: this.$t('leaveApproval.approvedLabel'),
      },
    ]
  }

  private get type(): any {
    return this.$route.params.approvalStatus
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'leaveApproval') {
      if (!to.params.approvalStatus) {
        this.$router
          .replace({ name: 'leaveApproval', params: { approvalStatus: 'approving' } })
          .catch(err => {})
      }
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'leaveApproval', params: { approvalStatus: key } }).catch(err => {})
  }
}
