
































































































































import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { DropDownController, TeacherController } from '@/services/request.service'
import GroupTransfer from '@/components/GroupTransfer.vue'
import cloneDeep from 'lodash/cloneDeep'

@Component({
  components: {
    GroupTransfer,
  },
})
export default class LeaveMessageModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly info!: any

  private loading: boolean = false
  private rejectForm: any
  private selectedTeachers: Array<any> = []
  private transferVisible = false
  private transferTitle = ''
  private transferCandidate: any = {}
  private transferSelected: Array<any> = []
  private teachers: any = {}
  private teacherLoading = false

  private get leaveTypes(): any {
    return [
      {
        key: 'personal',
        label: this.$t('attendance.statusList.personal'),
      },
      {
        key: 'illness',
        label: this.$t('attendance.statusList.illness'),
      },
      {
        key: 'holiday',
        label: this.$t('attendance.statusList.holiday'),
      },
      {
        key: 'exams',
        label: this.$t('attendance.statusList.exams'),
      },
      {
        key: 'others',
        label: this.$t('attendance.statusList.others'),
      },
    ]
  }

  private created(): void {
    this.rejectForm = this.$form.createForm(this)
  }

  private disabledDate(current): any {
    let currentSchoolYear = this.$store.state.currentSchoolYear
    if (!Object.keys(currentSchoolYear).length) return false
    return (
      current &&
      (current < moment(currentSchoolYear.startTime).startOf('day') ||
        current > moment(currentSchoolYear.endTime).startOf('day'))
    )
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(val): void {
    if (val) {
      this.$nextTick(() => {
        this.getTeachers()
        this.rejectForm.setFieldsValue({
          declinedReason: this.info.initContent,
          type: this.info.type === 'pass' ? 'personal' : undefined,
          leaveTime: this.info.time,
        })
      })
    }
  }

  private confirm(): void {
    this.rejectForm.validateFields((err: any, values: any) => {
      if (err) {
        this.loading = false
        return
      } else {
        this.$emit('confirm', {
          mes: values.declinedReason,
          type: values.type,
          startTime: values.leaveTime?.[0]?.valueOf(),
          endTime: values.leaveTime?.[1]?.valueOf(),
          teacherIds: this.selectedTeachers.map(teacher => teacher.id),
        })
        this.closeModal()
      }
    })
  }

  private removeSelectedTeacher(id): void {
    const index = this.selectedTeachers.findIndex(item => item.id === id)
    this.selectedTeachers.splice(index, 1)
  }

  private addTeacher(): void {
    this.transferVisible = true
    this.transferTitle = this.$t('diary.addTeacher') as string
    this.transferCandidate = this.teachers
    this.transferSelected = this.selectedTeachers
  }
  private getTeachers(): void {
    this.teacherLoading = true
    Promise.all([
      TeacherController.getListAll(),
      DropDownController.getHeadTeachers(),
      DropDownController.getCourseTeachers(),
      DropDownController.getTutors(),
    ])
      .then(res => {
        const allTeachers = res[0].data.map(teacher => {
          return {
            title: teacher.enName + teacher.name,
            id: teacher.teacherId,
            selected: false,
            type: 'teacher',
            relation: '',
          }
        })
        const headTeachers = res[1].data.map((grade, index) => {
          return {
            id: index,
            expand: false,
            title: grade.groupLabel || this.$t('transfer.ungrouped'),
            children: grade.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        const subjectTeachers = res[2].data.map((subject, index) => {
          return {
            id: index,
            expand: false,
            title: subject.groupLabel || this.$t('transfer.ungrouped'),
            children: subject.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        const tutors = res[3].data.map((advisory, index) => {
          return {
            id: index,
            expand: false,
            title: advisory.groupLabel || this.$t('transfer.ungrouped'),
            children: advisory.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        // let otherTeachers = [{
        //   id: 0,
        //   expand: false,
        //   title: this.$t('transfer.ungrouped'),
        //   children: removeDuplicateTeacher(allTeachers,headTeachers,subjectTeachers),
        // }]
        this.teachers = {
          all: {
            list: allTeachers,
            transferType: 'normal',
          },
          grade: {
            list: headTeachers,
            transferType: 'group',
          },
          advisory: {
            list: tutors,
            transferType: 'group',
          },
          subjectClass: {
            list: subjectTeachers,
            transferType: 'group',
          },
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.teacherLoading = false))
  }

  private getSelectedDataTeacher(selectedData): void {
    this.selectedTeachers = cloneDeep(selectedData)
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }
}
