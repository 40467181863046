

































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { CourseController, DropDownController, MomentsController } from '@/services/request.service'
import moment from 'moment'
import groupTransfer from '@/components/GroupTransfer.vue'
import { fileUploader } from '@/services/qiniu.service'
import cloneDeep from 'lodash/cloneDeep'
import { clearEmptyArray } from '@/utils/utils'

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
    groupTransfer,
  },
})
export default class MomentEditor extends Vue {
  private momentEditor: any
  private uploadAttachmentLoadingCount: number = 0
  private attachments: Array<any> = []
  private transferType: string = ''
  private transferTitle: string = ''
  private groupTransferVisible: boolean = false
  private selectedStudents: Array<any> = []
  private transferSelected: Array<any> = []
  private students: Array<any> = []
  private transferCandidate: Array<any> = []
  private canLeave: boolean = false
  private previewVisible: boolean = false
  private previewImage = null
  private moment = moment
  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private loading: boolean = false

  private get momentId(): any {
    return this.$route.query.momentId
  }

  private get editType(): any {
    return this.$route.query.type || 'add'
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private customRequest({ file }): void {
    const index = file.name.lastIndexOf('.')
    const suffix = file.name.slice(index).toLowerCase()
    // 构造文件名
    const fileName = 'file_' + new Date().getTime() + suffix
    const suffixArray = ['.jpg', '.jpeg', '.png']
    if (suffixArray.includes(suffix)) {
      if (file.size <= 104857600) {
        this.uploadAttachmentLoadingCount++
        fileUploader(file, fileName, 'file')
          .then(res => {
            let attachments = this.attachments
            if (this.attachments.length < 15) {
              attachments.push({
                uid: res.resourceId,
                url: process.env.VUE_APP_FILE_URL + res.key,
                name: file.name,
                status: 'done',
              })
            }
            this.attachments = attachments
            this.momentEditor.setFieldsValue({ attachments: this.attachments })
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.uploadAttachmentLoadingCount--
          })
      } else {
        this.$message.error(this.$tc('message.invalidFileSize'))
      }
    } else {
      this.$message.error(this.$tc('tips.invalidImg'))
    }
  }

  private removeFile(file): void {
    let index = this.attachments.indexOf(file)
    let tempList = this.attachments
    tempList.splice(index, 1)
    this.attachments = tempList
    this.momentEditor.setFieldsValue({ attachments: this.attachments })
  }

  private validateStudents(rule, value, cb): void {
    if (this.selectedStudents.length === 0) {
      cb(this.$t('tips.selectStudents'))
    } else {
      cb()
    }
  }

  private removeSelectedStudent(id): void {
    const index = this.selectedStudents.findIndex(item => item.id === id)
    this.selectedStudents.splice(index, 1)
    this.momentEditor.setFieldsValue({ studentIds: this.selectedStudents.map(item => item.id) })
  }

  private disabledDate(current): any {
    return current && current > moment().endOf('day')
  }

  private saveMoment(): void {
    this.loading = true
    this.momentEditor.validateFields((err: any, values: any) => {
      if (err) {
        this.loading = false
        return
      } else {
        let condition = {
          momentId: this.editType === 'edit' ? this.momentId : undefined,
          students: this.selectedStudents.map(student => student.id),
          content: values.content,
          resources: this.attachments.map(item => item.uid),
          time: values.time.valueOf(),
          shareWithParents: values.shareWithParents,
          shareWithStudents: values.shareWithStudents,
          isScratch: false,
        }
        MomentsController.saveMoment(condition)
          .then(res => {
            this.canLeave = true
            this.$route.meta.refresh = true
            this.$message.success(this.$tc('common.saveSuccess'))
            this.$router.push({ name: 'moment' })
          })
          .catch(err => console.log(err))
          .finally(() => (this.loading = false))
      }
    })
  }

  private saveDraft(): void {
    this.momentEditor.validateFields(
      ['content', 'time', 'studentIds'],
      { force: true },
      (err: any, values: any) => {
        if (err) {
          this.loading = false
          return
        } else {
          this.loading = true
          const isDraft = this.$route.query.isDraft
          let condition = {
            momentId: isDraft ? this.momentId : undefined,
            scratchFor: isDraft ? undefined : this.momentId,
            students: this.selectedStudents.map(student => student.id),
            content: this.momentEditor.getFieldValue('content') || '',
            resources: this.attachments.map(item => item.uid),
            time: this.momentEditor.getFieldValue('time')
              ? this.momentEditor.getFieldValue('time').valueOf()
              : undefined,
            shareWithParents: this.momentEditor.getFieldValue('shareWithParents'),
            shareWithStudents: this.momentEditor.getFieldValue('shareWithStudents'),
            isScratch: true,
          }
          MomentsController.saveMoment(condition)
            .then(res => {
              this.canLeave = true
              this.$route.meta.refresh = true
              this.$message.success(this.$tc('common.saveSuccess'))
              this.$router.push({ name: 'moment' })
            })
            .catch(err => console.log(err))
            .finally(() => (this.loading = false))
        }
      }
    )
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private beforeCreate(): void {
    this.momentEditor = this.$form.createForm(this)
  }

  private filterData(inputValue, path) {
    return path.some(option => option.label.indexOf(inputValue) > -1)
  }

  private getMomentInfo(momentId) {
    MomentsController.getMomentById(momentId)
      .then(res => {
        this.selectedStudents = (res.data.students || []).map(item => ({
          id: item.studentId,
          title: item.studentName,
        }))
        this.attachments = res.data.resources.map(item => {
          return {
            uid: item.resourceId,
            url: item.resourceUrl,
            name: item.resourceName,
            status: 'done',
          }
        })
        let data = {
          time: moment(res.data.time),
          content: res.data.content,
          studentIds: this.selectedStudents.map(item => item.id),
          attachments: this.attachments,
          shareWithParents: res.data.shareWithParents,
          shareWithStudents: res.data.shareWithStudents,
        }
        this.momentEditor.setFieldsValue(data)
      })
      .catch(err => console.log(err))
  }

  private addStudent(): void {
    this.transferType = 'student'
    this.groupTransferVisible = true
    this.transferTitle = this.$t('common.student') as string
    this.transferCandidate = this.students
    this.transferSelected = this.selectedStudents
  }

  public async handlePreview(file: any) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    this.previewImage = file.url || file.preview
    this.previewVisible = true
  }

  private getSelectedData(selectedData): void {
    this.selectedStudents = cloneDeep(selectedData)
    this.momentEditor.setFieldsValue({ studentIds: this.selectedStudents.map(item => item.id) })
  }

  private getStudents(): void {
    MomentsController.getStudents()
      .then(res => {
        this.students = res.data.map((grade, index) => {
          return {
            id: index,
            expand: false,
            title: grade.value || 'ungrouped',
            children: grade.subOptions.map(student => {
              return {
                id: student.key,
                title: student.value.trim(),
              }
            }),
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  private disabledDateTime(date) {
    let current = moment()
    if (current.date() === date.date()) {
      return {
        disabledHours: () => {
          return this.range(0, 24).splice(current.hour() + 1, 24)
        },
        disabledMinutes: () => {
          let current = moment()
          if (current.hour() === date.hour()) {
            return this.range(0, 60).splice(current.minute() + 1, 60)
          } else {
            return []
          }
        },
      }
    }
  }

  private range(start, end): any {
    const result = [] as any
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  private created() {
    this.getStudents()
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.query.type.includes('edit')) {
      this.getMomentInfo(to.query.momentId)
    }
  }
}
