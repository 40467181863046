



























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { DropDownController, MeetingsController } from '@/services/request.service'
import moment from 'moment'
import { createMergeArray } from '@/utils/utils'

@Component
export default class meeetingEditForm extends Vue {
  // 接受父组件的值
  @Prop({ default: () => [] }) private readonly data!: any
  @Prop() private readonly disabled!: any
  @Prop() private readonly classroomId!: number

  private info: any = {}
  private meetingEditor: any = {
    classRoomId: undefined,
    url: undefined,
  }
  private editMode: any = {
    classRoomId: false,
    url: false,
  }
  private canLeave: boolean = false
  private classrooms: any = []
  private formItemLayout: any = {
    labelCol: { span: 3 },
    wrapperCol: { span: 8 },
  }

  @Watch('data', { immediate: true })
  private onDataChange(val): void {
    if (!val.appointmentPeriods) return
    let selectList = val.appointmentPeriods.filter(period => {
      return !period.disabled
    })
    this.info = val
    this.meetingEditor = val
  }

  private saveMeetingSetting(): void {
    const form = this.$refs.meetingEditor as any
    const { classRoomId, url } = this.meetingEditor
    let condition = {
      appointeeId: this.data.appointeeId,
      classRoomId,
      url,
      publish: true,
    }
    form.validate(valid => {
      if (!valid) {
        return
      } else {
        MeetingsController.updateAppointments(condition as any)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.$emit('refreshInfo')
          })
          .catch(err => console.log(err))
      }
    })
  }

  private widowProp = {
    height: window.screen.height,
    width: window.screen.width,
  }

  private get locale(): any {
    return this.$store.state.locale
  }
  private get reservableTimeOptions(): any {
    if (!this.data.appointmentPeriods) return []
    return this.data.appointmentPeriods.map(item => {
      let { start, end } = item
      let day = moment(start).format('YYYY[.]MM[.]DD')
      let startTime = moment(start).format('HH:mm')
      let endTime = moment(end).format('HH:mm')
      return {
        value: item.appointmentId,
        label: `${day} ${startTime}~${endTime}`,
      }
    })
  }
  private get checkBoxList(): Array<any> {
    let length = Math.ceil(this.reservableTimeOptions.length / 3) * 3
    return [...Array(length).keys()]
  }

  private getDropDownInfo(): void {
    DropDownController.getClassRoomCascade()
      .then(res => {
        let classrooms = []
        res.data.forEach(item => {
          createMergeArray(item, classrooms)
        })
        this.classrooms = classrooms
      })
      .catch(err => {
        console.error(err)
      })
  }

  private created() {
    this.getDropDownInfo()
  }

  private beforeCreate(): void {
    this.meetingEditor = this.$form.createForm(this)
  }

  private edit(params): void {
    this.editMode[params] = true
  }

  private saveEdit(params): void {
    this.saveMeetingSetting()
    this.editMode[params] = false
  }

  private cancel(params): void {
    this.editMode[params] = false
    this.meetingEditor = this.data
  }

  private openLink(link): void {
    if (link.indexOf('http') !== -1) {
      window.open(link, '_blank')
    } else {
      window.open(`http://${link}`, '_blank')
    }
  }
}
