







































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  DropDownController,
  AttendanceController,
  TeacherController,
} from '@/services/request.service'
import { mapState } from 'vuex'
import moment from 'moment'
import debounce from 'lodash/debounce'
import DetailModal from '@/components/DetailModal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage } from '@/utils/utils'
import LeaveMessageModal from './LeaveMessageModal.vue'
import { createPagination } from '@/constant/constant'
import { saveAs } from 'file-saver'
import cloneDeep from 'lodash/cloneDeep'
import GroupTransfer from '@/components/GroupTransfer.vue'

const operations = {
  approving: function(values) {
    return AttendanceController.getPending(values[0], values[1], values[2], values[3], values[4])
  },
  approved: function(values) {
    return AttendanceController.getApproved(values[0], values[1], values[2], values[3], values[4])
  },
}
@Component({
  components: {
    DetailModal,
    FlexTooltip,
    filterGroup,
    LeaveMessageModal,
    GroupTransfer,
  },
  computed: {
    ...mapState({
      operationAuths: 'operationAuths',
    }),
  },
})
export default class ApprovalViewer extends Vue {
  private classes: Array<any> = []
  private detailModalShow: boolean = false
  private filter: any = {
    name: '',
    classId: undefined,
    houseGroupId: 0,
  }
  private houses: Array<any> = []
  private leaveData: Array<any> = []
  private loading: boolean = false
  private mesInfo: any = {
    id: '',
    title: '',
    tips: '',
    type: '',
    itemLabel: '',
    initContent: '',
    modifyTime: undefined,
  }
  private mesModalVis: boolean = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private rejectId: any = ''
  private requestInfo: any = {
    title: '',
    infoList: [],
    id: undefined,
    originData: {},
  }
  private modifyVis: any = false
  private editRecord: any = {}
  private modifyForm: any = {}
  private transferVisible = false
  private transferTitle = ''
  private transferCandidate: any = {}
  private transferSelected: Array<any> = []
  private teachers: any = {}
  private teacherLoading = false
  private currentDetail: any = {}

  private get columns(): Array<Object> {
    return [
      {
        dataIndex: 'student',
        key: 'name',
        title: this.$t('leaveApproval.student'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'studentNum',
        key: 'studentId',
        title: this.$t('leaveApproval.studentId'),
        scopedSlots: { customRender: 'studentId' },
      },
      {
        dataIndex: 'className',
        key: 'class',
        title: this.$t('leaveApproval.class'),
        scopedSlots: { customRender: 'class' },
      },
      {
        dataIndex: 'house',
        key: 'college',
        title: this.$t('leaveApproval.college'),
        scopedSlots: { customRender: 'college' },
      },
      {
        dataIndex: 'leaveTime',
        key: 'leaveTime',
        title: this.$t('leaveApproval.leaveTime'),
        scopedSlots: { customRender: 'leaveTime' },
      },
      this.type === 'approving'
        ? {
            dataIndex: 'reason',
            title: this.$t('leaveApproval.leaveReason'),
            scopedSlots: { customRender: 'leaveReason' },
          }
        : {
            dataIndex: 'leaveType',
            key: 'leaveType',
            title: this.$t('leaveApproval.leaveType'),
            scopedSlots: { customRender: 'leaveType' },
          },
      {
        dataIndex: 'status',
        key: 'status',
        title: this.$t('common.status'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'status' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        align: 'left',
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get dataSource(): any {
    return this.leaveData.map(item => {
      let startTime = moment(item.startTime).format('YYYY.MM.DD HH:mm')
      let endTime = moment(item.endTime).format('YYYY.MM.DD HH:mm')
      let leaveTime = `${startTime}~${endTime}`
      return {
        ...item,
        leaveDuration: item.durationInDays + this.$t('leaveApproval.days'),
        leaveType: this.$t(`leaveApproval.${item.type}`),
        leaveTime,
        statusString: this.$t(`leaveApproval.${item.status}`),
      }
    })
  }

  private get showChild(): boolean {
    return this.$route.name !== 'leaveApproval'
  }

  private get type(): any {
    return this.$route.params.approvalStatus
  }

  private disabledDate(current): any {
    let currentSchoolYear = this.$store.state.currentSchoolYear
    if (!Object.keys(currentSchoolYear).length) return false
    return (
      current &&
      (current < moment(currentSchoolYear.startTime).startOf('day') ||
        current > moment(currentSchoolYear.endTime).startOf('day'))
    )
  }
  @Watch('type')
  private onTypeChange(val): void {
    if (val) {
      this.getData()
    }
  }

  private addLeave(): void {
    this.$router.push({ name: 'leaveEditor', query: { type: 'add' } }).catch(err => {})
  }

  private beforeClose(): void {
    this.$confirm({
      title: this.$t('common.unsaveConfirm') as string,
      onOk: () => {
        this.modifyVis = false
      },
      onCancel: () => {},
    })
  }

  private cancelLeave(record): void {
    const { leaveApplicationId, modifyTime } = record
    AttendanceController.retrieveApplication(leaveApplicationId)
      .then(res => {
        this.$message.success(this.$tc('common.operateSuccess'))
        this.detailModalShow = false
      })
      .catch(err => console.log(err))
      .finally(() => this.refresh())
  }

  private changeName = debounce(() => {
    this.getData()
  }, 500)

  private cleanInfo(): void {
    this.mesInfo = {
      id: '',
      title: '',
      tips: '',
      type: '',
      itemLabel: '',
      initContent: '',
    }
  }

  private created() {
    this.getDropDownInfo()
    this.getTeachers()
  }

  private dealDetailData(data): any {
    let nameItem = {
        key: 'name',
        label: this.$t('leaveApproval.leavePerson'),
        value: '',
      },
      schoolBusItem = {
        key: 'schoolBus',
        label: this.$t('leaveApproval.schoolBus'),
        value: '',
      },
      accommodationItem = {
        key: 'accommodation',
        label: this.$t('leaveApproval.accommodation'),
        value: '',
      },
      dormitoryItem = {
        key: 'dormitory',
        label: this.$t('leaveApproval.dormitory'),
        value: '',
      },
      statusItem = {
        key: 'leaveStatus',
        label: this.$t('leaveApproval.leaveStatus'),
        value: '',
      },
      typeItem = {
        key: 'leaveType',
        label: this.$t('leaveApproval.leaveType'),
        value: '',
      },
      // durationItem = {
      //   key: 'leaveDuration',
      //   label: this.$t('leaveApproval.leaveDuration'),
      //   value: '',
      // },
      reasonItem = {
        key: 'leaveReason',
        label: this.$t('leaveApproval.leaveReason'),
        value: '',
      },
      timeItem = {
        key: 'leaveTime',
        label: this.$t('leaveApproval.leaveTime'),
        value: '',
      },
      attachmentItem = {
        key: 'attachment',
        label: this.$t('common.attachment'),
        value: '',
        visRow: 6,
      },
      noticeTeacherItem = {
        key: 'noticeTeacher',
        label: this.$t('diary.emailNoticeTeacher'),
        value: '',
        visRow: 6,
      },
      applyItem = {
        key: 'apply',
        label: this.$t('leaveApproval.apply'),
        value: '',
        visRow: 6,
      },
      rejectReasonItem = {
        key: 'rejectReason',
        label: this.$t('leaveApproval.rejectReason'),
        value: '',
        visRow: 6,
      }
    if (data) {
      nameItem.value = data.student
      // durationItem.value = data.leaveDuration
      schoolBusItem.value = data.schoolBus ? this.$tc('common.true') : this.$tc('common.false')
      accommodationItem.value = data.boarding ? this.$tc('common.true') : this.$tc('common.false')
      dormitoryItem.value = data.dormitory
      reasonItem.value = data.reason
      statusItem.value = data.statusString
      typeItem.value = data.leaveType
      timeItem.value = data.leaveTime
      rejectReasonItem.value = data.declineReason
      applyItem.value = data.approveReply
      attachmentItem.value = data.attachment
      noticeTeacherItem.value = data.teachers
    }
    return [
      nameItem,
      schoolBusItem,
      accommodationItem,
      data.boarding && dormitoryItem,
      statusItem,
      typeItem,
      timeItem,
      // durationItem,
      reasonItem,
      data?.status === 'declined' && rejectReasonItem,
      data?.status === 'approved' && applyItem,
      attachmentItem,
      data?.status === 'approved' && noticeTeacherItem,
    ].filter(item => item)
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private getDropDownInfo(): void {
    Promise.all([DropDownController.getHouseGroupListAll(), DropDownController.getClassListAll()])
      .then(res => {
        this.houses = res[0].data
        this.classes = res[1].data.map(item => {
          return {
            ...item,
            subOptions: item.subOptions.map(it => {
              return {
                key: it.key,
                value: it.value,
                enValue: it.enValue,
              }
            }),
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.getData()
      })
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    if (this.loading) return
    this.leaveData = []
    this.loading = true
    const name = this.filter.name ? this.filter.name.trim() : undefined
    let condition = [
      page.current,
      page.pageSize,
      (this.filter.classId || [])[1],
      this.filter.houseGroupId === 0 ? undefined : this.filter.houseGroupId,
      name,
    ]
    operations[this.type](condition)
      .then(res => {
        this.leaveData = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.leaveData, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(pagination)
  }

  private modalConfirm(params): void {
    const { id, modifyTime } = this.mesInfo
    switch (this.mesInfo.type) {
      case 'reject':
        AttendanceController.declineApplication({
          leaveApplicationId: id,
          reason: params.mes,
          modifyTime,
          type: params.type,
        })
          .then(res => {
            this.$message.success(this.$tc('common.operateSuccess'))
            this.mesModalVis = false
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.refresh()
            this.cleanInfo()
          })
        break
      case 'pass':
        AttendanceController.approve({
          leaveApplicationId: id,
          reason: params.mes,
          modifyTime,
          type: params.type,
          startTime: params.startTime,
          endTime: params.endTime,
          teacherIds: params.teacherIds,
        })
          .then(res => {
            this.$message.success(this.$tc('common.operateSuccess'))
          })
          .finally(() => {
            this.refresh()
            this.cleanInfo()
          })
        break
      case 'demo':
        AttendanceController.saveCommentTemplate(params.mes)
          .then(res => {
            this.$message.success(this.$tc('common.operateSuccess'))
          })
          .finally(() => this.cleanInfo())
        break
      default:
        this.$message.error('miss message type')
    }
  }

  private modifyConfirm(): void {
    const form = this.$refs['modifyForm'] as any
    form.validate(valid => {
      if (!valid) return false
      AttendanceController.updateLeaveTime(
        this.editRecord.leaveApplicationId,
        this.modifyForm.leaveTime[0].valueOf(),
        this.modifyForm.leaveTime[1].valueOf()
      ).then(res => {
        this.$message.success(this.$tc('common.saveSuccess'))
        this.modifyVis = false
        this.refresh()
      })
    })
  }

  private passLeave(record): void {
    AttendanceController.getCommentTemplate().then(res => {
      this.mesInfo = {
        id: record.leaveApplicationId,
        tips: this.$t(`leaveApproval.passConfirmTitle`),
        title: this.$t('leaveApproval.passConfirm'),
        type: 'pass',
        time: [moment(record.startTime), moment(record.endTime)],
        itemLabel: this.$t('message.reply'),
        initContent: res.data.value,
        modifyTime: record.modifyTime,
      }
      this.mesModalVis = true
      this.detailModalShow = false
    })
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private rejectLeave(record): void {
    this.mesInfo = {
      id: record.leaveApplicationId,
      tips: this.$t(`leaveApproval.rejectConfirmTitle`),
      title: this.$t('leaveApproval.rejectConfirm'),
      type: 'reject',
      itemLabel: this.$t('leaveApproval.rejectReason'),
      initContent: '',
      modifyTime: record.modifyTime,
    }
    this.mesModalVis = true
    this.detailModalShow = false
  }

  private reset(): void {
    this.filter = {
      name: '',
      classId: undefined,
      houseId: undefined,
    }
    this.pagination.current = 1
    this.refresh()
  }

  private showTimeModal(record): void {
    this.modifyVis = true
    this.editRecord = record
    this.modifyForm = {
      leaveTime: [moment(record.startTime), moment(record.endTime)],
    }
  }

  private showReplyDemo(): void {
    AttendanceController.getCommentTemplate().then(res => {
      this.mesInfo = {
        id: '',
        tips: '',
        itemLabel: this.$t(`common.replyDemo`),
        title: this.$t(`leaveApproval.replyDemo`),
        type: 'demo',
        initContent: res.data.value,
      }
      this.mesModalVis = true
    })
  }

  private timeValid(data): boolean {
    let current = moment()
    let target = moment(data.startTime)
    if (current.isAfter(target, 'day')) {
      return false
    } else {
      return true
    }
  }

  private viewDetails(singleRequest): void {
    this.currentDetail = singleRequest
    this.requestInfo = {
      title: this.$t('router.leaveApproval'),
      id: singleRequest.leaveApplicationId,
      infoList: this.dealDetailData(singleRequest),
      originData: singleRequest,
      isHistory: singleRequest.isHistory,
    }
    this.mesInfo = {
      id: '',
      tips: '',
      itemLabel: '',
      title: '',
      type: singleRequest.status,
      initContent: '',
    }
    this.detailModalShow = true
  }

  private downloadAttachment(attachment): void {
    saveAs(attachment.resourceUrl, attachment.name)
  }

  private addTeacher(): void {
    this.transferVisible = true
    this.transferTitle = this.$t('diary.addTeacher') as string
    this.transferCandidate = this.teachers
    this.transferSelected = this.requestInfo.infoList
      .filter(item => item.key === 'noticeTeacher')[0]
      .value.map(item => {
        return {
          id: item.teacherId,
          title: item.enName + ' ' + item.name,
          type: 'teacher',
        }
      })
  }
  private getTeachers(): void {
    this.teacherLoading = true
    Promise.all([
      TeacherController.getListAll(),
      DropDownController.getHeadTeachers(),
      DropDownController.getCourseTeachers(),
      DropDownController.getTutors(),
    ])
      .then(res => {
        const allTeachers = res[0].data.map(teacher => {
          return {
            title: teacher.enName + teacher.name,
            id: teacher.teacherId,
            selected: false,
            type: 'teacher',
            relation: '',
          }
        })
        const headTeachers = res[1].data.map((grade, index) => {
          return {
            id: index,
            expand: false,
            title: grade.groupLabel || this.$t('transfer.ungrouped'),
            children: grade.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        const subjectTeachers = res[2].data.map((subject, index) => {
          return {
            id: index,
            expand: false,
            title: subject.groupLabel || this.$t('transfer.ungrouped'),
            children: subject.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        const tutors = res[3].data.map((advisory, index) => {
          return {
            id: index,
            expand: false,
            title: advisory.groupLabel || this.$t('transfer.ungrouped'),
            children: advisory.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        // let otherTeachers = [{
        //   id: 0,
        //   expand: false,
        //   title: this.$t('transfer.ungrouped'),
        //   children: removeDuplicateTeacher(allTeachers,headTeachers,subjectTeachers),
        // }]
        this.teachers = {
          all: {
            list: allTeachers,
            transferType: 'normal',
          },
          grade: {
            list: headTeachers,
            transferType: 'group',
          },
          advisory: {
            list: tutors,
            transferType: 'group',
          },
          subjectClass: {
            list: subjectTeachers,
            transferType: 'group',
          },
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.teacherLoading = false))
  }

  private getSelectedDataTeacher(selectedData): void {
    const { leaveApplicationId, reason, modifyTime, type, startTime, endTime } = this.currentDetail
    AttendanceController.approve({
      leaveApplicationId,
      reason,
      modifyTime,
      type,
      startTime,
      endTime,
      teacherIds: selectedData.map(item => item.id),
    })
      .then(res => {
        this.$message.success(this.$tc('common.operateSuccess'))
        this.requestInfo.infoList.forEach(item => {
          if (item.key === 'noticeTeacher') {
            item.value = selectedData.map(it => {
              return {
                teacherId: it.id,
                enName: it.title.split(' ')[0],
                name: it.title.split(' ')[1],
              }
            })
          }
        })
      })
      .finally(() => {
        this.refresh()
      })
  }
}
