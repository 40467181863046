









































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import filterGroup from '@/components/filterGroup'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { NoticeController } from '@/services/request.service'
import { createPagination } from '@/constant/constant'
import debounce from 'lodash/debounce'

import moment from 'moment'
import { toPage, guid, judgeVnode } from '@/utils/utils'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
  },
})
export default class ContentManagement extends Vue {
  private filter: any = {
    title: '',
    department: 0,
    date: null,
    // status: -1,
  }
  private manageForm: any = {
    endingDate: undefined,
  }
  private pagination: any = createPagination({ pageSize: 30 })

  private data: Array<any> = [{}]
  private moment = moment
  private loading: boolean = false

  private departmentList: any = []
  private visible: boolean = false
  // private statuses: Array<any> = [
  //   {
  //     key: true,
  //     value: 'published',
  //   },
  //   {
  //     key: false,
  //     value: 'unpublish',
  //   },
  // ]

  private rules: any = {
    // endingDate: [
    //   { required: true, message: this.$t('notice.enterEngingDate'), trigger: 'change' },
    // ],
    endingDate: [{ required: true, message: this.$t('notice.enterEngingDate'), trigger: 'change' }],
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'contentManagement'
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get columns(): Array<any> {
    return [
      {
        // dataIndex: 'title',
        key: 'title',
        title: this.$t('notice.title'),
        ellipsis: true,
        scopedSlots: { customRender: 'noticeTitle' },
        width: 500 * this.ratio,
      },
      {
        key: 'department',
        dataIndex: 'department',
        title: this.$t('notice.department'),
        scopedSlots: { customRender: 'department' },
        width: 150 * this.ratio,
      },
      {
        key: 'creater',
        dataIndex: 'creator',
        title: this.$t('notice.creater'),
        // scopedSlots: { customRender: 'creater' },
        width: 120 * this.ratio,
      },
      {
        key: 'releaseTime',
        title: this.$t('notice.releaseTime'),
        scopedSlots: { customRender: 'releaseTime' },
        width: 200 * this.ratio,
      },
      // {
      //   key: 'status',
      //   dataIndex: 'status',
      //   title: this.$t('notice.status'),
      //   scopedSlots: { customRender: 'status' },
      //   width: 150 * this.ratio,
      // },
      {
        key: 'operations',
        title: this.$t('notice.operations'),
        align: 'left',
        scopedSlots: { customRender: 'operations' },
        width: 150 * this.ratio,
      },
    ].filter(item => item)
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private disabledDate(current): any {
    return current && current < moment()
  }

  private edit(id): void {
    this.$router.push({ name: 'noticeEdit', params: { id: '1' } })
  }

  private reset(): void {
    this.filter = {
      title: '',
      department: '0',
      date: null,
      // status: null,
    }
    this.pagination.current = 1
    // this.refresh()
  }

  private confirmEdit(noticeId): void {
    const form = this.$refs.manageForm as any
    form.validate(valid => {
      if (valid) {
        // NoticeController.sticky(noticeId, true, this.manageForm.endingDate.valueOf())
        //   .then((res) => {
        //     this.$message.success(this.$tc('common.saveSuccess'))
        //   })
        //   .catch((err) => console.log(err))
        this.changeSticky(noticeId, true, this.manageForm.endingDate.valueOf())
      }
    })
  }

  private cancelTop(noticeId): void {
    this.changeSticky(noticeId, false)
  }

  /**
   * 更新置顶状态
   */
  private async changeSticky(noticeId: number, sticky: boolean, endingDate?: number): Promise<any> {
    await NoticeController.sticky(noticeId, sticky, endingDate ? endingDate : undefined)
      .then(res => {
        this.$message.success(this.$tc('common.saveSuccess'))
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.manageForm = {
          date: undefined,
        }
        this.visible = false
        this.refresh()
      })
  }

  private recycle(noticeId): void {
    NoticeController.recycle(noticeId, false)
      .then(res => {
        this.$message.success(this.$tc('common.saveSuccess'))
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.refresh()
      })
  }

  private deleteRecord(noticeId): void {
    NoticeController.delete(noticeId)
      .then(res => {
        this.$message.success(this.$tc('common.deleteSuccess'))
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.refresh()
      })
  }

  private getFilters(): void {
    NoticeController.departDropDown()
      .then(res => {
        this.departmentList = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    if (this.loading) return
    //   const { sectionIds } = this.filter
    this.data = []
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      title: this.filter.title === '' ? undefined : this.filter.title.trim(),
      department: this.filter.department ? this.filter.department : undefined,
      date: this.filter.date ? this.filter.date.valueOf() : undefined,
      // status: this.filter.status === -1 ? undefined : this.filter.status === 1 ? true : false,
      // status: this.filter.status === undefined ? undefined : this.filter.status
    }
    this.loading = true
    NoticeController.getRecycledNotices(condition)
      .then(res => {
        this.data = res.data.items

        /**
         * test
         */
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private refresh(): void {
    this.getData(this.pagination)
  }

  // private created(): void {
  //   NoticeController.getNotices({ pageCurrent: 1, pageSize: 30 }).then((res) => {
  //     this.data = res.data.items
  //   })
  // }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    this.getFilters()
    this.refresh()
  }
}
