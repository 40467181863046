






























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// import TeacherEditor from './TeacherEditor.vue'
import moment from 'moment'
import { DropDownController, AddressBookController } from '@/services/request.service'

Component.registerHooks(['beforeRouteLeave'])
import { TeacherController } from '@/services/request.service'

@Component({
  components: {
    // TeacherEditor
  },
})
export default class TeacherDetail extends Vue {
  private teacherInfo: any = {
    name: '',
    enName: '',
  }
  // private editorVisible: boolean = false
  private data: any = []
  private teachInfo: any = []
  private loading: boolean = false
  private get infoList(): any {
    return [
      {
        label: this.$t('addressBook.type'),
        value:
          this.type === '2003'
            ? this.$t('addressBook.staff')
            : this.$t('addressBook.externalStaff'),
      },
      {
        label: this.$t('addressBook.gender'),
        value: this.teacherInfo.gender ? this.$t('common.gender.' + this.teacherInfo.gender) : '',
      },
      {
        label: this.$t('addressBook.position'),
        value: this.teacherInfo.position,
      },
      {
        label: this.$t('addressBook.email'),
        value: this.teacherInfo.email,
      },
    ]
  }
  private get teacherId(): any {
    return this.$route.query.teacherId
  }
  private get type(): any {
    return this.$route.query.type
  }
  private get canEditRole(): any {
    return this.$store.state.userInfo.canEditRole
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private get columns(): any {
    return [
      {
        dataIndex: 'teacherTypeName',
        key: 'teacherTypeName',
        title: this.$t('addressBook.teacherType'),
        align: 'left',
        scopedSlots: { customRender: 'teacherTypeName' },
      },
      {
        dataIndex: 'teachInfo',
        key: 'teachInfo',
        title: this.$t('addressBook.teachInfo'),
        width: '80%',
        align: 'left',
        scopedSlots: { customRender: 'teachInfo' },
      },
    ]
  }
  // private get data(): any {
  //   return this.teacherInfo?.teachingList || []
  // }

  private copyEmail(text): void {
    // const text = res.data[2002] + '/courseSelection?csqId=' + id
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', text)
    input.select()
    // ios兼容
    input.setSelectionRange(0, text.length)
    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    }
    document.body.removeChild(input)
    this.$message.success(this.$tc('common.copySuccess'))
  }

  private getTeacherDetail(teacherId): void {
    AddressBookController.detail(teacherId)
      .then(res => {
        // const { teacherId, roleName, subjects, email, ...teacherInfo } = res.data
        this.teacherInfo = res.data
        this.data = res.data.teachingList
      })
      .catch(err => {
        console.error(err)
      })
  }

  // private getTeachInfo(teacherId): void {
  //   this.loading = true
  //   TeacherController.getTeachingInfo(teacherId)
  //     .then(res => {
  //       this.teachInfo = res.data
  //     })
  //     .catch(err => console.log(err))
  //     .finally(() => this.loading = false)
  // }

  private refresh(): void {
    this.getTeacherDetail(this.teacherId)
    // this.getTeachInfo(this.teacherId)
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.getTeacherDetail(to.query.teacherId)
    // this.getTeachInfo(to.query.teacherId)
  }
}
